/*
grey #37454E
peach #DB4F5A
site color
*/

/*
|----------------------------------------------------------------------
|       Body Main Css
|----------------------------------------------------------------------
*/

@font-face {
  font-family: "Montserrat Light";
  src: url("./assets/fonts/Montserrat-Light.ttf");
}

@font-face {
  font-family: "Montserrat Italic";
  src: url("./assets/fonts/Montserrat-Italic.ttf");
}

@font-face {
  font-family: "Montserrat Regular";
  src: url("./assets/fonts/Montserrat-Regular.ttf");
}

@font-face {
  font-family: "Montserrat Medium";
  src: url("./assets/fonts/Montserrat-Medium.ttf");
}

@font-face {
  font-family: "Montserrat SemiBold";
  src: url("./assets/fonts/Montserrat-SemiBold.ttf");
}

@font-face {
  font-family: "Montserrat Bold";
  src: url("./assets/fonts/Montserrat-Bold.ttf");
}

@font-face {
  font-family: "Montserrat Black";
  src: url("./assets/fonts/Montserrat-Black.ttf");
}

@font-face {
  font-family: "Poppins SemiBold";
  src: url("./assets/fonts/Poppins-SemiBold.ttf");
}

.light {
  font-family: "Montserrat Light" !important;
}

.italic {
  font-family: "Montserrat Italic" !important;
}

.regular {
  font-family: "Montserrat Regular" !important;
}

.medium {
  font-family: "Montserrat Medium" !important;
}

.semi {
  font-family: "Montserrat SemiBold" !important;
}

.bold {
  font-family: "Montserrat Bold" !important;
}

.black {
  font-family: "Montserrat Black" !important;
}

.price {
  font-family: "Montserrat Bold";
}

:root {
  --chevron: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%230a2130" viewBox="-255 347 100 100" style="enable-background:new -255 347 100 100;" xml:space="preserve"><path d="M-205,425.0830078c-1.6635742,0-3.3266602-0.6347656-4.5961914-1.9033203l-43.1665039-43.1669922 c-2.5385742-2.5385742-2.5385742-6.6538086,0-9.1923828c2.5380859-2.5380859,6.6542969-2.5380859,9.1923828,0L-205,409.390625 l38.5712891-38.5703125c2.5380859-2.5390625,6.6542969-2.5380859,9.1923828,0 c2.5380859,2.5385742,2.5380859,6.6538086-0.0009766,9.1923828l-43.1669922,43.1669922 C-201.6728516,424.4482422-203.3369141,425.0830078-205,425.0830078z"/></svg>');
  --tick: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%23fff" viewBox="0 0 300 300" style="enable-background:new 0 0 300 300;" xml:space="preserve"><polygon points="115.7434006,270.8831787 0,173.503006 21.3676376,148.106369 111.6297684,224.0477905 274.5331421,29.1168175 300,50.3998222"/></svg>');
  --question: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="300px" height="300px" fill="%230a2130" viewBox="-155 247 300 300" style="enable-background:new -155 247 300 300;"><path d="M-5,265c-72.9016113,0-132,59.0983887-132,132S-77.9016113,529-5,529s132-59.0984497,132-132 S67.9016113,265-5,265z M-5.47052,468.3762817c-7.4328003,0-13.4794922-6.0470581-13.4794922-13.4790649 c0-7.4320679,6.0466919-13.479126,13.4794922-13.479126c7.432373,0,13.4794922,6.0470581,13.4794922,13.479126 C8.0089722,462.3292236,1.961853,468.3762817-5.47052,468.3762817z M34.1941528,371.9846802 c-2.6166992,12.1121216-11.4063721,21.4836426-19.1619263,29.7519531c-0.956665,1.0199585-1.8609619,1.9437866-2.7167969,2.8183594 c-3.1091309,3.1724243-4.4174805,4.5682983-5.5913696,7.2835083c-1.3748169,3.1787109-1.890686,6.8474121-1.5335083,10.9046021 c0.4846191,5.5038452-3.5842896,10.3590088-9.0881348,10.8435669c-0.298584,0.0258179-0.5952148,0.0391235-0.8890381,0.0391235 c-5.1287231,0-9.4957886-3.9219971-9.9542236-9.1272583c-0.6522217-7.40625,0.3908081-14.3372803,3.0997314-20.602417 c2.6948853-6.2315063,6.2381592-9.8479004,9.6646118-13.3450317c0.7612915-0.7765503,1.5654907-1.5960083,2.4151001-2.5018311 c6.2698364-6.6848755,12.7529907-13.5967407,14.1989136-20.2897949c1.2380371-5.7316895-0.3782959-13.9069824-5.8634033-18.4417114 c-6.9536743-5.7469482-18.0630493-4.09198-24.7865601,0.8272705c-10.0702515,7.3675537-8.9369507,21.3914185-8.9244385,21.5324707 c0.5568848,5.4968872-3.4495239,10.3880005-8.9467163,10.9448853c-5.5003662,0.5560913-10.406311-3.4647827-10.9631958-8.9619751 c-0.953125-9.4090576,1.5588379-28.3513794,17.0200195-39.6635132c13.0418091-9.5411377,34.1811523-12.6392822,49.3492432-0.1000366 C33.7728882,344.0241089,36.7139893,360.3235474,34.1941528,371.9846802z"/></svg>');
}

html {
  height: 100%;
}

body {
  position: relative;
  min-height: 100%;
  background-color: #fff;
  color: #333;
  font-size: 14px;
  font-family: "Montserrat Medium";
  margin: 0;
  padding: 0;
  line-height: 1.6;
  word-break: break-word;
  transition: all ease 0.5s;
  overflow-x: hidden;

}

body {
  display: flex;
  flex-flow: column;
}

body.flow {
  overflow: hidden !important;
}

body>main {
  flex: 1;
  padding-top: 80px;
  overflow: hidden;
}

::selection {
  background: #db4f5a;
  color: #fff;
}

::-moz-selection {
  background: #db4f5a;
  color: #fff;
}

body a {
  color: #db4f5a;
  word-break: break-word;
}

body a,
body span {
  display: inline-block;
  text-decoration: none;
}

body a:hover {
  color: #db4f5a;
}

body a:focus,
body a:hover {
  outline: none !important;
  text-decoration: none !important;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  display: inline-block;
}

ul li a {
  transition: all ease 0.5s;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat Bold";
  line-height: 1.3;
  margin: 0 0 10px;
}

p {
  color: #2e2e2e;
  margin: 0 0 15px;
}

p:nth-last-child(1) {
  margin: 0;
}

p>a {
  color: #e93037;
}

p>a:hover {
  color: #ff6c71;
}

.relative {
  position: relative;
}

.tableDv {
  display: table;
  width: 100%;
  height: 100%;
}

.tableCell {
  display: table-cell;
  vertical-align: middle;
}

.toggle {
  position: absolute;
  top: 21px;
  right: 5px;
  display: flex;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px;
  transition: all ease 0.5s;
  z-index: 4;
}

.toggle span,
.toggle span:before,
.toggle span:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background: #333;
  border-radius: 50px;
  transition: all ease 0.5s;
}

.toggle span {
  position: relative;
  display: block;
  width: 22px;
  margin: 8px auto;
}

.toggle span:before {
  top: -7px;
}

.toggle span:after {
  bottom: -7px;
}

.toggle.active span {
  width: 22px;
  background: transparent !important;
}

.toggle.active span:before,
.toggle.active span:after {
  top: 0;
}

.toggle.active span:before {
  transform: rotate(405deg);
}

.toggle.active span:after {
  transform: rotate(-45deg);
}

.blockquote {
  background: #fafafa;
  color: #151d23;
  padding: 10px 10px 10px 20px;
  border: 1px solid #f0f5f9;
  border-left: 4px solid #db4f5a;
}

.heading {
  position: relative;
  margin-bottom: 25px;
}

.heading>em {
  color: #db4f5a;
}

.sub_heading {
  color: #db4f5a;
  font-size: 15px;
  text-transform: uppercase;
}

p.pre {
  margin: -10px 0 30px;
}

.color {
  color: #db4f5a !important;
}

.background {
  background: #db4f5a !important;
}

/*
|----------------------------------------------------------------------
|       Body Main Button
|----------------------------------------------------------------------
*/

.webBtn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 46px;
  background: #db4f5a;
  color: #fff;
  font-size: 14px;
  font-family: "Montserrat SemiBold";
  text-align: center;
  padding: 0 30px;
  white-space: nowrap;
  border: 0;
  border-radius: 5px;
  outline: none !important;
  box-shadow: rgb(81 24 24 / 20%) 0 5px 10px 0;
}

.webBtn:hover {
  background: #383838;
  color: #fff;
}

.emptyBtn {
  background: transparent;
  border: 1px solid #db4f5a;
  color: #db4f5a;
}

.googleBtn {
  background: transparent;
  color: #848484;
}

.fbBtn {
  background: transparent;
  color: #4267b2;
}

.smBtn {
  min-width: 40px;
  height: 36px;
  padding: 0 20px;
}

.mdBtn {
  min-width: 80px;
  height: 40px;
}

.lgBtn {
  /* height: 54px; */
  font-size: 16px;
  padding: 0 60px;
}

.blankBtn {
  background: transparent;
  color: #db4f5a;
  border: solid 1px #db4f5a;
  box-shadow: none;
  padding: 0 40px;
}

.blankBtn:hover {
  background: transparent;
  color: #000;
  border: solid 1px #db4f5a;
}

.lightBtn {
  background: transparent;
  color: #db4f5a;
}

.lightBtn:hover {
  background: transparent;
  color: #fff;
}

.labelBtn {
  min-width: initial;
  height: auto;
  background: transparent;
  color: #db4f5a;
  padding: 0;
  border: 0;
  box-shadow: none;
}

.labelBtn:hover {
  background: transparent;
  color: #fff;
}

.labelBtn>i {
  font-size: 100%;
}

.simpleBtn {
  background: #fff;
  color: #080a0f;
}

.simpleBtn:hover {
  background: #fff;
  color: #db4f5a;
}

.simpleBtn.icoBtn>img {
  filter: none;
}

.greenBtn {
  background-color: #11c693;
  min-width: 95px;
  padding: 0 25px;
}

.grayBtn {
  background: #fff;
  color: #2e2e2e;
  border: 1px solid #eee;
}

.grayBtn:hover,
.greenBtn:hover {
  background: #fafafa;
  color: #2e2e2e;
}

.borderBtn {
  border: 1px solid #db4f5a;
}

.arrowBtn {
  display: flex !important;
  width: 46px;
  min-width: 46px;
  padding: 0 5px;
}

.arrowBtn>i {
  font-weight: bold;
  margin: 0 !important;
}

.arrowBtn>img {
  width: 18px;
}

.icoBtn>img {
  width: 18px;
  margin-right: 10px;
  filter: brightness(0) invert(1);
}

.roundBtn {
  border-radius: 50px;
}

.blockBtn {
  width: 100%;
}

.blockBtn em {
  flex: 1;
  text-align: left;
}

.webBtn i {
  font-size: 140%;
  margin-right: 10px;
}

.webBtn.smBtn i {
  font-size: 120%;
}

.webBtn i.fi-arrow-right {
  font-weight: bold;
  margin-left: 10px;
  margin-right: 0;
}

.webBtn i.fi-arrow-left {
  font-weight: bold;
  margin-left: 0;
  margin-right: 10px;
}

/*_____ webBtn spinner _____*/

.webBtn .spinner,
.donate_btn_active .spinner {
  width: auto;
  height: auto;
  background: inherit;
  border-radius: inherit;
  overflow: hidden;
}

.webBtn .spinner~i,
.donate_btn_active .spinner~i {
  visibility: hidden;
}

.webBtn .spinner,
.webBtn .spinner:before,
.webBtn .spinner:after,
.donate_btn_active .spinner:before,
.donate_btn_active .spinner:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.webBtn .spinner:before,
.webBtn .spinner:after,
.donate_btn_active .spinner:before,
.donate_btn_active .spinner:after {
  content: "";
  width: 18px;
  height: 18px;
  border: 3px solid;
  animation: spinner 0.5s linear infinite;
  border-radius: 75%;
}

.webBtn .spinner:before,
.donate_btn_active .spinner:before {
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.webBtn .spinner:after,
.donate_btn_active .spinner:after {
  border-top-color: transparent;
  border-left-color: transparent;
  opacity: 0.5;
}

.webBtn.smBtn .spinner:before,
.webBtn.smBtn .spinner:after {
  width: 14px;
  height: 14px;
}

.webBtn.lgBtn .spinner:before,
.webBtn.lgBtn .spinner:after {
  width: 20px;
  height: 20px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

*[disabled] {
  cursor: not-allowed !important;
}

a[disabled],
button[disabled] {
  opacity: 0.8;
}

.learnBtn {
  background: transparent;
  color: #db4f5a;
  font-family: "Montserrat SemiBold";
  padding: 0;
  text-transform: capitalize;
  border: 0;
}

.learnBtn i {
  position: relative;
  top: 3px;
  font-weight: bold;
  margin-left: 5px;
  /* transform: scale(-1); */
  transition: padding ease 0.5s;
}

.learnBtn:hover i {
  padding-left: 10px;
}

/*
|----------------------------------------------------------------------
|       Body Inner Css
|----------------------------------------------------------------------
*/

h1 {
  font-size: 40px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 22px;
}

h4 {
  font-size: 18px;
}

h5 {
  font-size: 16px;
}

h6 {
  font-size: 14px;
}

img {
  width: 100%;
  display: block;
}

em {
  font-style: normal;
}

strong {
  font-family: "Montserrat SemiBold";
  font-weight: normal;
}

input,
button,
select,
textarea {
  text-decoration: none !important;
  outline: none !important;
}

.ease,
body a,
body button,
body .webBtn {
  transition: all ease 0.5s;
}

.contain,
.contain-fluid {
  position: relative;
  max-width: 1400px;
  padding: 0 15px;
  margin: auto;
  min-height: 1px;
}

.contain-fluid {
  max-width: 1600px;
  padding: 0 50px;
}

.contain:before,
.contain:after,
.contain-fluid:before,
.contain-fluid:after {
  content: "";
  display: table;
}

.contain:after,
.contain-fluid:after {
  clear: both;
}

section {
  position: relative;
}

.flex {
  display: flex;
  flex-wrap: wrap;
}

.flex-end {
  justify-content: flex-end;
}

.formRow {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.formRow>[class*="col-"],
.formRow>[class^="col-"] {
  padding: 10px;
}

.flexBox {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flexDv {
  width: 100%;
}

.flexRow {
  margin: -15px;
}

.flexRow>.col {
  padding: 15px;
}

.bTn {
  display: flex;
  flex-flow: wrap;
  gap: 5px;
}

.bTn.formBtn {
  margin-top: 20px;
}

.text-left.bTn,
.text-left .bTn {
  justify-content: flex-start;
}

.text-center.bTn,
.text-center .bTn {
  justify-content: center;
}

.text-right.bTn,
.text-right .bTn {
  justify-content: flex-end;
}

/*_____ upperlay _____*/

.upperlay {
  position: fixed;
  top: 0;
  /* left: 280px; */
  right: 0;
  bottom: 0;
  background: rgb(10 33 48 / 0.9);
  opacity: 0;
  visibility: hidden;
  backdrop-filter: blur(5px);
  transition: all ease 0.5s;
  z-index: 100;
}

.upperlay.active {
  opacity: 1;
  visibility: visible;
}

/*
|----------------------------------------------------------------------
|       Popup
|----------------------------------------------------------------------
*/

.popup {
  /* display: none; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 33, 37, 0.95);
  padding: 20px 0;
  overflow: auto;
  z-index: 101;
}

.popup ._inner {
  position: relative;
  max-width: 900px;
  /*background: #f7f8f9;*/
  background: #fff;
  padding: 40px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

#profile .popup ._inner {
  background-color: #000;
}

#profile .popup ._inner .crosBtn {
  opacity: 1;
  left: 20px;
  width: 20px;
}

.popup.big-popup ._inner {
  max-width: 800px;
}

.popup.small-popup ._inner {
  max-width: 420px;
}

.popup ._inner h3 {
  padding-right: 25px;
  margin-bottom: 20px;
}

.popup ._inner h4 {
  padding-right: 25px;
  margin-bottom: 15px;
}

.popBtn {
  cursor: pointer;
}

.crosBtn {
  position: absolute;
  top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 5px;
  width: 28px;
  height: 28px;
  color: #2e2e2e;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
  z-index: 5;
  font-family: "Font Awesome 6 Free";
}

.crosBtn:before {
  content: "\f104";
  line-height: 1;
}

.popup .list>li {
  display: flex;
  margin-bottom: 10px;
}

.popup .list>li>div:nth-child(1) {
  width: 120px;
  min-width: 120px;
  font-family: "Montserrat Bold";
  margin-right: 10px;
}

.popup .list>li>div:nth-child(2) {
  width: 100%;
}

.popup .blockLst {
  margin: -15px;
}

/*
|----------------------------------------------------------------------
|       Dropdown
|----------------------------------------------------------------------
*/

.dropBtn {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.dropBtn>i.chevron {
  display: inline-block;
  width: 10px;
  min-width: 10px;
  height: 10px;
  background-image: var(--chevron);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0;
  margin-left: 5px;
  transition: inherit;
}

.dropDown {
  position: relative;
  z-index: 1;
}

.dropCnt {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 160px;
  max-width: 260px;
  background: #fff;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  /* border: 1px solid #eee; */
  border-radius: 7px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all ease 0.5s;
  overflow: hidden;
}

.dropCnt.right {
  left: initial;
  right: 0;
}

.dropCnt.active {
  margin-top: 0;
  visibility: visible;
  opacity: 1;
}

.dropLst {
  /* border: 1px solid #eee; */
  padding: 6px 0;
  /* border-radius: 7px; */
}

.dropLst>li,
.dropLst>li>* {
  display: block;
}

/* .dropLst > li:nth-child(1) > * {
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.dropLst > li:nth-last-child(1) > * {
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
}

.dropLst > li:not(:nth-last-child(1)) {
    border-bottom: 1px solid #eee;
} */

.dropLst>li>* {
  display: flex;
  align-items: center;
  width: 100%;
  background: #fff;
  /* color: #fff; */
  text-align: left;
  padding: 6px 15px;
  border: 0;
}

.dropLst>li>*:hover {
  background: #fafafa;
}

.dropLst>li>*>i {
  margin-right: 6px;
}

/*_____ more _____*/

.more {
  position: relative;
  width: 20px;
  min-width: 20px;
  height: 20px;
  /* padding-bottom: 10px; */
  margin: 0 auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.5s;
}

.more>span,
.more>span:before,
.more>span:after {
  width: 4px;
  height: 4px;
  border-radius: 75%;
  background: #aaa;
  transition: all ease 0.5s;
  z-index: 2;
}

.more>span:before,
.more>span:after {
  position: absolute;
  content: "";
}

.more>span:before {
  left: 0;
}

.more>span:after {
  right: 0;
}

.more:hover>span,
.more:hover>span:before,
.more:hover>span:after {
  background: #fff;
}

/*
|----------------------------------------------------------------------
|       Page Loader
|----------------------------------------------------------------------
*/

#pageloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #f5f6f8;
  z-index: 999999;
}

#pageloader.hidden {
  display: none !important;
}

.loader {
  width: 40px;
  height: 40px;
  border: 4px solid #db4f5a;
  border-right: 4px solid #f0f5f9;
  border-radius: 75%;
  animation: spin 0.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

/*
|----------------------------------------------------------------------
|       Ico Css
|----------------------------------------------------------------------
*/

.ico,
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.ico {
  background: #fff;
  border-radius: 75%;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  /* overflow: hidden; */
}

.ico a,
.icon a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.ico img,
.icon img {
  width: 100%;
  height: 100%;
  object-position: center;
}

.ico img {
  object-fit: cover;
  border-radius: inherit;
}

.icon img {
  object-fit: contain;
}

.ico>i.status {
  position: absolute;
  top: 0;
  right: -4px;
  display: block;
  width: 12px;
  height: 12px;
  /* background: #43b753; */
  background: #db4f5a;
  border: 2px solid #fff;
  border-radius: 75%;
}

.ico>i.status.active {
  background: #43b753;
  /* background: #DB4F5A; */
}

.ico>i.status.away {
  background: #ffcb00;
}

.ico>i.status.busy {
  background: #5883ff;
}

.ico>i.status.offline {
  background: #ff5465;
}

.ico>.loading {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 50%; */
  min-width: 100%;
  height: 3px;
  background: #eee;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  overflow: hidden;
}

.ico>.loading:before {
  content: "";
  width: inherit;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #db4f5a;
}

/*
|----------------------------------------------------------------------
|       RateYo
|----------------------------------------------------------------------
*/

.jq-ry-container {
  position: relative;
  display: block;
  cursor: pointer;
  box-sizing: content-box;
  line-height: 0;
  direction: ltr;
}

.jq-ry-container[readonly="readonly"] {
  cursor: default;
}

.jq-ry-container>.jq-ry-group-wrapper {
  position: relative;
  width: 100%;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group {
  position: relative;
  line-height: 0;
  white-space: nowrap;
  z-index: 1;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group>svg {
  display: inline-block;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group.jq-ry-normal-group {
  width: 100%;
}

.jq-ry-container>.jq-ry-group-wrapper>.jq-ry-group.jq-ry-rated-group {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  overflow: hidden;
  z-index: 3;
}

/*
|----------------------------------------------------------------------
|       Editor
|----------------------------------------------------------------------
*/

.ckEditor {
  /* Font */
  /* Emoji fonts are added to visualise them nicely in Internet Explorer. */
  font-family: "Montserrat Regular", sans-serif, Arial, Verdana, "Trebuchet MS",
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 14px;
}

.ckEditor .cke_editable {
  font-size: 13px;
  line-height: 1.6;

  /* Fix for missing scrollbars with RTL texts. (#10488) */
  word-wrap: break-word;
}

.ckEditor blockquote {
  font-style: italic;
  font-family: Georgia, Times, "Times New Roman", serif;
  padding: 2px 0;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
}

.ckEditor .cke_contents_ltr blockquote {
  padding-left: 20px;
  padding-right: 8px;
  border-left-width: 5px;
}

.ckEditor .cke_contents_rtl blockquote {
  padding-left: 8px;
  padding-right: 20px;
  border-right-width: 5px;
}

.ckEditor a {
  display: inline;
  color: #0782c1;
}

.ckEditor ol,
.ckEditor ul,
.ckEditor dl {
  /* IE7: reset rtl list margin. (#7334) */
  *margin-right: 0px;
  /* Preserved spaces for list items with text direction different than the list. (#6249,#8049)*/
  padding: 0 40px;
}

.ckEditor ul,
.ckEditor ol {
  padding: 0;
  padding-left: 20px;
  margin: 0 0 10px;
  list-style-position: inside;
}

.ckEditor ul>li,
.ckEditor ol>li {
  display: list-item;
  margin-bottom: 5px;
}

.ckEditor ul>li ul,
.ckEditor ol>li ol {
  margin: 5px 0 7px;
}

.ckEditor h1,
.ckEditor h2,
.ckEditor h3,
.ckEditor h4,
.ckEditor h5,
.ckEditor h6 {
  font-weight: normal;
  line-height: 1.2;
}

.ckEditor hr {
  border: 0px;
  border-top: 1px solid #ccc;
}

.ckEditor img.right {
  border: 1px solid #ccc;
  float: right;
  margin-left: 15px;
  padding: 5px;
}

.ckEditor img.left {
  border: 1px solid #ccc;
  float: left;
  margin-right: 15px;
  padding: 5px;
}

.ckEditor pre {
  white-space: pre-wrap;
  /* CSS 2.1 */
  word-wrap: break-word;
  /* IE7 */
  -moz-tab-size: 4;
  tab-size: 4;
}

.ckEditor .marker {
  background-color: Yellow;
}

.ckEditor span[lang] {
  font-style: italic;
}

.ckEditor figure {
  text-align: center;
  outline: solid 1px #ccc;
  background: rgba(0, 0, 0, 0.05);
  padding: 10px;
  margin: 10px 20px;
  display: inline-block;
}

.ckEditor figure>figcaption {
  text-align: center;
  display: block;
  /* For IE8 */
}

.ckEditor a>img {
  padding: 1px;
  margin: 1px;
  border: none;
  outline: 1px solid #0782c1;
}

/* Widget Styles */
.ckEditor .code-featured {
  border: 5px solid red;
}

.ckEditor .math-featured {
  padding: 20px;
  box-shadow: 0 0 2px rgba(200, 0, 0, 1);
  background-color: rgba(255, 0, 0, 0.05);
  margin: 10px;
}

.ckEditor .image-clean {
  border: 0;
  background: none;
  padding: 0;
}

.ckEditor .image-clean>figcaption {
  font-size: 0.9em;
  text-align: right;
}

.ckEditor .image-grayscale {
  background-color: white;
  color: #666;
}

.ckEditor .image-grayscale img,
.ckEditor img.image-grayscale {
  filter: grayscale(100%);
}

.ckEditor .embed-240p {
  max-width: 426px;
  max-height: 240px;
  margin: 0 auto;
}

.ckEditor .embed-360p {
  max-width: 640px;
  max-height: 360px;
  margin: 0 auto;
}

.ckEditor .embed-480p {
  max-width: 854px;
  max-height: 480px;
  margin: 0 auto;
}

.ckEditor .embed-720p {
  max-width: 1280px;
  max-height: 720px;
  margin: 0 auto;
}

.ckEditor .embed-1080p {
  max-width: 1920px;
  max-height: 1080px;
  margin: 0 auto;
}

.ckEditor i,
.ckEditor em {
  font-style: italic;
}

.ckEditor b,
.ckEditor strong {
  font-weight: bold;
}

.ckEditor u {
  text-decoration: underline;
}

.ckEditor span {
  display: initial;
  text-decoration: inherit;
}

/*
|----------------------------------------------------------------------
|       Header
|----------------------------------------------------------------------
*/

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 130px;
  z-index: 30;
  background: #fff;
}

header .contain-fluid {
  padding: 0 15px;
}

.logo {
  float: left;
  display: flex;
  align-items: center;
  width: 220px;
  height: 80px;
}

.logo>a {
  display: block;
  height: 100%;
}

.logo>a>img {
  display: block;
  width: auto;
  height: 100%;
  max-width: 100%;
  object-fit: contain;
  object-position: left center;
  transition: inherit;
}

nav {
  display: flex !important;
  align-items: center;
  height: 80px;
  margin-left: 260px;
}

nav>ul {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -20px;
}

nav>#nav {
  margin: 0 auto;
}

nav>ul>li {
  position: relative;
  padding: 0 15px;
}

nav>ul>li>a {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  color: #000;
  text-align: center;
  font-family: "Montserrat Medium";
}

nav>ul>li>a:hover,
nav>ul>li.active>a:not(.webBtn) {
  color: #db4f5a;
}

#nav>li.drop>a {
  position: relative;
}

#nav>li.drop>a:after {
  content: "\e93d";
  font-size: 12px;
  font-family: "font-icon";
  font-weight: bold;
  margin-left: 6px;
  line-height: 1;
}

#nav>li>.sub {
  position: absolute;
  top: 100%;
  left: 15px;
  width: 240px;
  background: #fff;
  text-align: left;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  border-radius: 10px;
  transition: none;
  box-shadow: 0 15px 30px -5px rgb(0 0 0 / 7%);
  overflow: hidden;
}

#nav>li:hover>.sub {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  transition: all 0.2s ease-out;
}

#nav>li>.sub>li,
#nav>li>.sub>li>a {
  display: block;
}

#nav>li>.sub>li>a {
  color: #000;
  font-size: 13px;
  padding: 13px 18px;
  border-bottom: solid 1px #eee;
}

#nav>li>.sub>li>a:hover {
  color: #db4f5a;
}

nav #cta {
  margin-left: 40px;
}

/*====================topNav============*/
.topNav {
  background: #37454e;
  padding: 10px 0px;
}

.topNav .colL {
  width: 65%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}

.topNav .colL .webBtn {
  height: 30px;
  color: #fff !important;
}

.topNav .colR {
  width: 35%;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topNav .colL p {
  margin-bottom: 0px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.topNav .colL p:not(:last-child) {
  padding-right: 30px;
}

.topNav .colL p i {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  margin-right: 5px;
  font-size: 16px;
  color: #fff;
}

.topNav .colL p a {
  color: #fff;
  font-family: "Montserrat Regular";
}

.topNav .colR>a:not(:last-child) {
  margin-right: 33px;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.topNav .colR .social li a {
  color: #fff;
  font-size: 16px;
  margin-right: 5px;
  width: 23px;
  height: 23px;
  /*background: #fff;*/
  border-radius: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.topNav .colR .social {
  padding-left: 20px;
}

/*_____ Header Fix _____*/

header.fix {
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
}

/*_____ miniLbl _____*/

.miniLbl {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #db4f5a;
  color: #fff;
  font-size: 70%;
  font-family: "Montserrat SemiBold";
  border-radius: 75%;
  white-space: nowrap;
}

.green {
  background: #43b753;
}

.green-color {
  color: #43b753;
}

.yellow {
  background: #ffcb00;
}

.yellow-color {
  color: #ffcb00;
}

.red {
  background: #ff5465;
}

.red-color {
  color: #ff5465;
}

.blue {
  background: #5883ff;
}

.white-color {
  color: #fff;
}

.gray {
  background: #aaa;
}

.gray-color {
  color: #aaa;
}

.index>section {
  padding: 60px 0;
}

.donate_now .sub-banner {
  height: 240px;
}

.contact {
  padding-top: 130px;
}

/*
|----------------------------------------------------------------------
|       Banner
|----------------------------------------------------------------------
*/

.index {
  padding-top: 110px;
}

#banner {
  /* height: 700px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 80px;
}

#banner .flex_1 {
  margin-bottom: 40px;
  gap: 20px;
}

#banner .flex_1 .video {
  flex: 1;
  height: 448px;
}

#banner .flex_1 .side-poster {
  margin-top: -5px;
}

#banner .contain {
  z-index: 3;
}

#banner .vid .col {
  width: calc(100% / 4);
  padding: 10px;
  margin-bottom: 25px;
}

#banner .vid .col .image {
  position: relative;
  height: 150px;
  overflow: hidden;
  width: 100%;
  border-radius: 3px;
}

#banner a {
  color: #fff;
}

#banner .vid .col .image img {
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

#banner .vid .col .contnt {
  margin-top: 10px;
}

#banner .vid .col .contnt h4 {
  font-size: 17px;
}

#banner .vid .col .view-detail li:not(:last-child) {
  padding-right: 9px;
}

#banner .vid .col .view-detail li {
  margin-right: 13px;
  position: relative;
}

#banner .vid .col .view-detail li:not(:last-child):after {
  position: absolute;
  content: "";
  right: -5px;
  height: 5px;
  top: 9px;
  width: 5px;
  border-radius: 50%;
  background-color: #ddd;
}

#banner .vid .col .view-detail li em {
  align-self: center;
  font-size: 13px;
  color: #ababab;
}

#banner .vid .col .image .playIcon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

#banner .vid .col .image .playIcon i {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50px;
  border: 3px solid #ffffffc2;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
  padding-left: 5px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

/*_____ owl-nav _____*/

.owl-carousel.owl-theme .owl-nav {
  position: absolute;
  top: 48%;
  left: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  transition: all ease 0.5s;
}

.owl-carousel.owl-theme:hover .owl-nav {
  opacity: 1;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  top: 0;
  width: initial;
  height: initial;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  color: #ffffff;
  font-size: 24px;
  padding-top: 1px;
  padding-left: 1px;
  margin-top: -12px;
}

.owl-carousel.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #db4f5a;
}

.owl-carousel.owl-theme .owl-nav .owl-prev {
  left: 4px;
}

.owl-carousel.owl-theme .owl-nav .owl-next {
  right: 4px;
}

/*_____ owl-dots _____*/

.owl-carousel.owl-theme .owl-dots {
  text-align: right;
  position: relative;
  margin-top: 20px;
  line-height: 0;
  z-index: 5;
}

.owl-carousel.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  padding: 0 0px !important;
}

.owl-carousel.owl-theme .owl-dots .owl-dot span {
  display: block;
  width: 10px;
  height: 4px;
  margin: auto;
  background-color: #aaa;
  border-radius: 50px;
  transition: all ease 0.5s;
}

.owl-carousel.owl-theme .owl-dots .owl-dot.active span {
  width: 24px;
  height: 4px;
  margin: auto;
  background-color: #ffffff;
}

/*
|----------------------------------------------------------------------
|       Form
|----------------------------------------------------------------------
*/

label {
  display: block;
  font-weight: normal;
  cursor: pointer;
}

label>a {
  color: #3c9cdb;
}

label>a:hover {
  color: #db4f5a;
}

.txtGrp {
  position: relative;
}

.txtGrp:not(:nth-last-child(1)) {
  margin-bottom: 20px;
}

.infoHead {
  margin-top: 40px;
  margin-bottom: 20px;
}

form h5+p {
  margin: -10px 0 20px;
}

.txtBox {
  display: block;
  width: 100%;
  height: 44px;
  background: #fafafa;
  text-align: left;
  padding: 6px 12px;
  border: 1px solid #d0d0d0;
  border-radius: 6px;
  outline: none !important;
  transition: all ease 0.5s;
}

.txtGrp label~.txtBox:not(button) {
  padding: 16px 12px 6px;
}

.txtBox:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
}

textarea.txtBox {
  height: 130px;
  resize: none;
}

.txtGrp .txtBox.uploadImg:not([data-upload]) {
  background: #fff;
  color: #db4f5a;
  text-align: center;
  border-color: #db4f5a;
  box-shadow: inset 1px 1px 4px #fcfcfc;
}

.txtGrp .txtBox.uploadImg[data-upload] {
  display: flex;
  align-items: center;
  color: #2e2e2e;
}

.txtGrp .txtBox.uploadImg[data-upload]:before {
  content: attr(data-text);
  flex: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.txtGrp .txtBox.uploadImg[data-upload]:after {
  content: "Hochladen";
  color: #db4f5a;
  margin-left: 5px;
}

.txtGrp .txtBox.uploadImg[data-upload].uploaded:after {
  content: "Entfernen";
}

.txtGrp:not(.lblBtn)>label {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  min-height: 46px;
  color: #fff;
  line-height: 1;
  margin: 0;
  margin-left: 13px;
  pointer-events: none;
  transition: all ease 0.5s;
  z-index: 1;
}

.txtGrp:not(.lblBtn)>label.move {
  font-size: 10px;
  transform: translateY(-12px);
}

.txtBox:disabled {
  background: #e3e8ee;
  cursor: not-allowed;
  border-color: #d8dee6 !important;
}

.txtBox::placeholder {
  color: #020202;
}

.txtBox:focus::placeholder {
  opacity: 0.4;
}

.txtGrp .txtBox:focus::placeholder {
  opacity: 0;
}

.txtBox:hover,
.txtBox:focus {
  border-color: #db4f5a;
}

input[type="radio"],
input[type="checkbox"] {
  position: relative;
  width: 18px;
  min-width: 18px;
  height: 18px;
  margin-top: 0;
  cursor: pointer;
}

input[type="radio"],
input[type="checkbox"] {
  display: inline;
  appearance: initial;
}

input[type="radio"]:before,
input[type="checkbox"]:before,
input[type="radio"]:after,
input[type="checkbox"]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

input[type="checkbox"]:before,
input[type="checkbox"]:after {
  transition: all linear 0.3s;
}

input[type="radio"]:before,
input[type="checkbox"]:before {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  z-index: 1;
}

input[type="radio"]:after,
input[type="checkbox"]:after {
  background: #fff;
  border: 1px solid #d9dade;
  border-radius: 4px;
  box-shadow: inset 1px 1px 4px #f2f3f7;
}

input[type="radio"]:after {
  border-radius: 75%;
}

input[type="checkbox"]:checked:before {
  background-image: var(--tick);
}

input[type="radio"]:checked:after {
  border-color: #db4f5a;
  box-shadow: inset 0 0 0 4px #db4f5a;
}

input[type="checkbox"]:checked:after {
  background: #db4f5a;
  border-color: #db4f5a;
  box-shadow: none;
}

.space {
  margin: 10px;
}

.lblBtn {
  display: flex;
  text-align: left;
}

.lblBtn>input[type="radio"],
.lblBtn>input[type="checkbox"] {
  margin-top: 2px;
}

.lblBtn label {
  align-self: center;
  line-height: 1.5;
  cursor: pointer;
  margin: 0 0 0 10px;
}

input[type="file"].uploadFile {
  display: none;
}

.uploadImg {
  position: relative;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

select.txtBox {
  background-image: var(--chevron);
  background-position: right 10px center;
  background-repeat: no-repeat;
  background-size: 10px;
  padding-right: 24px !important;
  -webkit-appearance: none;
  appearance: none;
}

/*_____ flexGrp _____*/

.flexGrp {
  display: flex;
}

.flexGrp>div,
.flexGrp>.bTn>.webBtn {
  align-self: center;
  margin: 0;
}

.flexGrp>.txtGrp {
  flex: 1;
  margin: 0 !important;
}

.flexGrp>.txtGrp.auto {
  flex: none;
}

.flexGrp>.bTn {
  margin-left: 10px;
}

.flexGrp>.data {
  margin-right: 15px;
}

.flexGrp>.data>* {
  margin: 0;
}

/*_____ info _____*/

.infoHead {
  display: flex;
  margin-bottom: 0;
}

.infoHead>*:not(.info) {
  margin-top: 0;
}

.info {
  position: relative;
  align-self: flex-start;
  color: #2e2e2e;
  line-height: 1;
  margin-left: auto;
}

.info>strong {
  display: flex;
  align-items: center;
  font-size: 90%;
  font-family: "Montserrat SemiBold" !important;
  cursor: pointer;
}

.info>strong:after {
  content: "";
  display: block;
  width: 20px;
  min-width: 20px;
  height: 20px;
  background-image: var(--question);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 5px;
  filter: brightness(0) invert(0.6);
}

.info .infoIn {
  position: absolute;
  top: 100%;
  right: 0;
  width: 240px;
  background: rgb(255 255 255 / 0.7);
  padding: 20px;
  margin: 20px 0 0;
  line-height: 1.4;
  text-align: left;
  font-size: 90%;
  opacity: 0;
  visibility: hidden;
  border-radius: 6px;
  backdrop-filter: blur(10px);
  box-shadow: 0 15px 35px 0 rgba(49, 49, 93, 0.1),
    0 5px 15px 0 rgba(0, 0, 0, 0.08);
  transition: all ease 0.5s;
  z-index: 2;
}

.info:hover .infoIn {
  margin-top: 7px;
  opacity: 1;
  visibility: visible;
}

/*
|----------------------------------------------------------------------
|       Index page
|----------------------------------------------------------------------
*/
.cntntt {
  width: 650px;
  margin: 0 auto;
}

.content {
  width: 890px;
  margin: 0 auto 40px;
}

.margn_top_30 {
  margin-top: 30px;
}

.margn_bottom_30 {
  margin-bottom: 30px;
}

/*******_____ lives _____*******/
.lives .content p {
  margin: 40px 0;
}

.lives .imagee {
  position: absolute;
}

.lives .imagee:nth-child(1) {
  top: 60px;
  left: 40px;
  width: 130px;
}

.lives .imagee:nth-child(2) {
  top: -10px;
  right: 40px;
  width: 125px;
}

/*******_____ map _____*******/
.map {
  /* height: 700px; */
  background-image: url(./assets/images/map_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.map .content {
  margin: 40px auto 60px;
}

.map .content .sml {
  margin: 0 auto;
  width: 74%;
}

/*******_____ Listen Now _____*******/
.listen_now {
  background: #f7f7f7;
}

.listen_now .colL {
  width: 50%;
  padding-right: 20px;
}

.listen_now .colR {
  width: 50%;
  padding-left: 20px;
  align-self: center;
}

.listen_now .colR .flex {
  gap: 20px;
}

.listen_now .colR .lis_img {
  width: 120px;
}

.listen_now .colR h6 {
  color: #db4f5a;
}

/* ===========progress bar========= */
.ProgressBox {
  display: flex;
  justify-content: center;
}

.progress-box {
  width: calc(100% / 3);
  padding-left: 30px;
}

.progress-box .blk {
  width: 100%;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.progress-box .blk p {
  color: #fff;
  width: 160px;
  text-align: center;
}

.ProgressBox .inner_ {
  display: flex;
  align-items: center;
  position: absolute;
  height: 90%;
  z-index: 3;
  width: 200px;
  justify-content: center;
}

/* ============progress bar================ */

.circle_percent {
  font-size: 200px;
  width: 200px;
  height: 200px;
  position: relative;
  background: #db4f5a;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;
}

.circle_inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  clip: rect(0 1em 1em 0.5em);
}

.round_per {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
  height: 200px;
  background: #f11242;
  clip: rect(0 1em 1em 0.5em);
  transform: rotate(180deg);
  transition: 1.05s;
}

.percent_more .circle_inner {
  clip: rect(0 0.5em 1em 0em);
}

.percent_more:after {
  position: absolute;
  left: 0.5em;
  top: 0em;
  right: 0;
  bottom: 0;
  background: #f11242;
  content: "";
}

.circle_inbox {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #263036;
  z-index: 3;
  border-radius: 50%;
}

.percent_text {
  position: absolute;
  font-size: 36px;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
  z-index: 3;
  font-family: "Montserrat Bold";
}

/*******_____ ways _____*******/
.ways .flexRow>.col {
  width: calc(100% / 3);
}

.ways .inner {
  height: 100%;
  background: #f1f1f1;
  padding: 30px 20px;
  border-radius: 2px;
  overflow: hidden;
}

.ways .inner>.icon {
  width: 70px;
  min-width: 70px;
  height: 70px;
  margin: 0 auto 20px;
}

.ways h4 {
  text-transform: uppercase;
}

/*******_____ impact counter _____*******/
.impact_counter {
  /* height: 700px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.impact_counter .flexRow {
  margin: 50px 0 0 0;
}

.impact_counter .flexRow>.col {
  width: calc(100% / 3);
}

.impact_counter .flexRow>.col h1 {
  font-size: 50px;
}

/*******_____ spponsers _____*******/
.spponsers .flexRow>.col {
  width: calc(100% / 4);
  padding: 20px;
  align-self: center;
}

/*******_____ subbscribe _____*******/
.subbscribe {
  background: #f7f7f7;
}

.subbscribe .imagee {
  position: absolute;
}

.subbscribe .imagee:nth-child(1) {
  top: 0px;
  left: 40px;
  width: 190px;
}

.subbscribe .imagee:nth-child(2) {
  top: -10px;
  right: 40px;
  width: 150px;
}

.subbscribe .flexGrp {
  flex-flow: wrap;
  gap: 10px;
  margin: 30px 0 40px;
}

.subbscribe .txtBox {
  flex: 1;
}

/*******_____ danger _____*******/

.danger .flexRow {
  margin-top: 50px;
}

.danger .flexRow>.col {
  width: calc(100% / 2);
  padding: 20px;
}

.danger .flexRow .inner {
  height: 100%;
  background: #f1f1f1;
  padding: 50px 10px 50px 40px;
  border-radius: 2px;
  gap: 60px;
  overflow: hidden;
}

.danger .flexRow .inner .icon {
  background: #db4f5a;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
}

.danger .flexRow .inner .text {
  flex: 1;
}

.danger .flexRow .inner .text p:last-child a {
  text-decoration: underline;
}

.danger .flexRow .inner h5 {
  font-family: "Montserrat Medium";
}

/*
|----------------------------------------------------------------------
|       Footer
|----------------------------------------------------------------------
*/

footer {
  position: relative;
  background: #3c3e45;
  color: #fff;
  font-family: "Montserrat Regular";
  padding-top: 40px;
  overflow: hidden;
}

footer .flexRow {
  margin: -15px;
}

footer .flexRow>.col:nth-child(1) {
  width: 50%;
  padding-right: 88px;
}

footer .flexRow>.col {
  width: 25%;
  padding: 15px;
}

footer .footerLogo {
  max-width: 130px;
}

footer h5 {
  position: relative;
  /* font-family: 'Montserrat Light'; */
  margin-bottom: 25px;
  padding-bottom: 10px;
}

footer h5:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background: #db4f5a;
}

footer .lst>li {
  display: block;
  margin-bottom: 10px;
}

footer .lst>li:nth-last-child(1) {
  margin-bottom: 0 !important;
}

footer a {
  color: inherit;
}

footer p {
  color: #fff;
}

footer a:hover {
  color: #db4f5a;
}

footer .smLst {
  margin: -5px -10px;
}

footer .infoLst>li {
  display: flex;
  margin-bottom: 20px;
}

/* footer .infoLst > li > img {
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-top: 5px;
  margin-right: 10px;
} */

footer .articleBlk .icon {
  margin-left: 30px;
}

footer .articleBlk .txt>h6 {
  font-size: inherit;
  font-family: "Montserrat Light";
}

footer .articleBlk .txt>.date {
  font-family: inherit;
  opacity: 0.5;
}

footer .smLst>li {
  padding: 5px 10px;
}

footer .social {
  margin: 30px -3px -3px;
}

footer .social>li {
  padding: 3px;
}

footer .social>li>a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: 38px;
  min-width: 38px;
  height: 38px;
  background: #fff;
  background: #313238;
  padding: 11px;
  border-radius: 10px;
}

footer .social>li>a:hover {
  background: #db4f5a;
}

footer .social>li>a>img {
  filter: brightness(0) invert(1);
}

/*_____ Copyright _____*/

.copyright {
  padding: 12px 0;
  background: #303136;
  margin-top: 60px;
  border-top: 1px solid rgb(255 255 255 / 7%);
  z-index: 1;
}

.copyright .inner {
  display: flex;
  justify-content: center;
}

.copyright p {
  flex: 1;
  margin: 0;
}

.copyright p>a {
  color: #db4f5a;
}

.copyright p>a:hover {
  color: #fff;
}

/*
|----------------------------------------------------------------------
|       Block
|----------------------------------------------------------------------
*/

.blk {
  background: #fff;
  padding: 25px;
  margin-bottom: 20px;
  border-radius: 7px;
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

.blk .blk {
  padding: 20px;
}

.blk ._header,
.blk ._footer {
  position: relative;
  display: flex;
  align-items: center;
}

.blk ._header {
  margin-bottom: 20px;
}

.blk.collap>._header {
  margin-bottom: 0;
}

.blk.collap>._header>.downBtn,
.blk.collap>._header>.closeBtn {
  display: block;
  font-family: "font-icon";
  font-weight: bold;
  line-height: 2;
  margin-left: 10px;
  cursor: pointer;
}

.blk.collap>._header>.downBtn:before {
  content: "\e93d";
}

.blk.collap>._header>.closeBtn:before {
  content: "\ea06";
  display: block;
  transform: rotate(45deg);
}

.blk.collap>._header>.closeBtn:hover {
  color: #ff5465;
}

.blk.collap>._content {
  display: none;
  padding-top: 20px;
}

.blk.collap.active>._content {
  display: block;
}

.blk.collap>._content>h5:nth-child(1) {
  margin-top: 20px;
}

.blk.head ._header {
  margin-bottom: -20px;
}

.blk ._footer {
  justify-content: flex-end;
}

.blk ._header h3,
.blk ._header h4,
.blk ._header h5,
.blk ._header h6 {
  margin-bottom: 0;
  margin-right: 30px;
  flex: 1;
}

.blk ._header h3>span {
  color: #2e2e2e;
}

.blk ._header+p {
  margin: -10px 0 20px;
}

.blk ._header .srch {
  width: 280px;
}

/*_____ scrollbar  _____*/

.scrollbar::-webkit-scrollbar-track {
  background: #eff0f3;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #eff0f3;
  border-radius: 50px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.07);
  border-radius: 50px;
}

/*
|----------------------------------------------------------------------
|       Formal - Common
|----------------------------------------------------------------------
*/

[formal]>section,
.common>section {
  padding: 60px 0;
}

[formal]>section:nth-child(1) {
  padding-top: 20px;
}

[typical] {
  padding-top: 0;
}

[typical] #sBanner {
  padding-top: 140px;
}

[typical] #sBanner:before {
  opacity: 0.7;
}

[typical] #sBanner .txtGrp {
  color: #fff;
  border-radius: 7px;
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
  overflow: hidden;
}

[typical] #sBanner .txtGrp>img {
  position: absolute;
  top: 0;
  left: 16px;
  bottom: 0;
  width: 18px;
  height: 18px;
  margin: auto;
  filter: brightness(0) invert(0.5);
}

[typical] #sBanner .txtGrp>.txtBox,
[typical] #sBanner .txtGrp>.webBtn {
  height: 56px;
  box-shadow: none;
}

[typical] #sBanner .txtGrp>.txtBox {
  padding-left: 46px;
  border: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

[typical] #sBanner .txtGrp>.webBtn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/*_____ topHead _____*/

.topHead {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.topHead .txtBox {
  width: auto;
  max-width: 120px;
  height: 36px;
}

.topHead .txtBox+.webBtn {
  margin-left: 15px;
}

.topHead .heading {
  margin: 0;
  margin-right: auto;
}

/*
|----------------------------------------------------------------------
|       Sub Banner
|----------------------------------------------------------------------
*/

#sBanner {
  min-height: 100px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#sBanner:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0d171d;
  opacity: 0.5;
  z-index: 1;
}

#sBanner .contain {
  z-index: 3;
}

#sBanner .content {
  max-width: 700px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
}

#sBanner .content>h1 {
  text-shadow: 5px 5px 5px rgb(41 41 47 / 20%);
  margin-bottom: 0;
}

#sBanner .content>p {
  color: inherit;
  font-size: 16px;
}

#sBanner .content>h1+* {
  margin-top: 20px;
}

/*
|----------------------------------------------------------------------
|       Terms
|----------------------------------------------------------------------
*/

#terms {
  padding-top: 30px;
}

#terms .outer {
  background: #01121d;
  padding: 25px;
  border-radius: 7px;
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

/*
|----------------------------------------------------------------------
|       Oops 404
|----------------------------------------------------------------------
*/

#oops {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
  padding: 120px 0 60px;
  overflow: auto;
}

#oops .blk {
  max-width: 440px;
  margin: 0 auto;
}

#oops .logoDv {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  width: 120px;
  margin: 0 auto 30px;
}

#oops .icon {
  color: #fff;
  font-size: 140px;
  font-family: "Montserrat Bold";
  line-height: 0.8;
  margin-bottom: 25px;
  text-shadow: 0 0 2px #db4f5a, 1px 5px 24px rgba(68, 102, 242, 0.05);
}

/*
|----------------------------------------------------------------------
|       Videos
|----------------------------------------------------------------------
*/

.vidBlk {
  position: relative;
  display: block;
  /* background: #000d16; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 60%;
  cursor: pointer;
  border-radius: 7px;
  /* box-shadow: 0 5px 20px rgb(18 15 56 / 7%); */
  overflow: hidden;
}

.vidBlk:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 14%;
  min-width: 36px;
  max-width: 100px;
  background-image: url("./assets/images/play.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin: auto;
  opacity: 0.9;
  transition: all linear 0.5s;
}

.vidBlk:hover:before {
  opacity: 1;
}

.vidBlk video,
.vidBlk iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 0 !important;
  margin: auto;
  /* -o-object-fit: cover; */
  object-fit: fill;
}

video[poster] {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.validation-error {
  color: red;
}

/*
|----------------------------------------------------------------------
|       Contact us
|----------------------------------------------------------------------
*/

#contact {
  padding: 0 !important;
  height: 850px;
}

#contact .content p {
  font-size: 18px;
}

#contact .flex .flex {
  margin: 30px 0;
}

#contact .colL {
  width: 45%;
  padding-right: 20px;
}

#contact .colR .gray-color {
  margin-bottom: 60px;
}

#contact .colR .coll {
  padding: 30px;
}

#contact .colR .coll:nth-child(1) {
  flex: 1;
  padding-left: 0;
}

#contact .colR .coll:last-child {
  padding-right: 0;
}

#contact .colR .coll .icon {
  margin-bottom: 30px;
  width: 50px;
}

#contact .colR {
  width: 50%;
  padding: 20px 12% 20px 0px;
}

#contact form {
  /* background: #01121d; */
  /* max-width: 500px; */
  /*padding: 25px;
   margin: 0 auto;
  border-radius: 7px;
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%); */
}

#contact form label,
#contact form .txtBox:not(textarea) {
  height: 50px;
}

#contact form .txtBox {
  padding-top: 20px;
}

#contact .infoLst {
  margin: -10px;
}

#contact .infoLst>li {
  width: 33.3334%;
  padding: 10px;
}

#contact .infoLst>li .inner {
  height: 100%;
  background: #fff;
  padding: 40px 30px 30px;
  border-radius: 7px;
  box-shadow: 0 5px 20px rgb(18 15 56 / 7%);
}

#contact .infoLst>li .icon {
  width: 42px;
  min-width: 42px;
  height: 42px;
  margin: 0 auto 15px;
}

/*
|----------------------------------------------------------------------
|      other pages
|----------------------------------------------------------------------
*/

/* / ===================================mini-label===================== / */
.mini-label {
  padding: 15px 0px;
  background-color: #efefef;
}

.mini-label ul {
  display: flex;
  flex-wrap: wrap;
  gap: 35px;
  margin-bottom: 0;
}

.mini-label ul li a {
  color: #37454e;
  position: relative;
}

.mini-label ul li:first-child a {
  color: #37454e7c;
}

.mini-label ul li:last-child a:before {
  position: absolute;
  content: "\e941";
  top: 4px;
  left: -21px;
  color: #37454e;
  font-family: "font-icon";
  font-size: 11px;
  font-weight: 600;
}

/* / ===============sub-banner=============== / */
.sub-banner {
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.human-trafficking-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sub-banner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  /* opacity: 0.7; */
  background: rgb(0 0 0 / 79%);
}

.sub-banner .cntnt {
  width: 715px;
  margin: 0px auto;
  text-align: center;
}

.sub-banner .cntnt h1 {
  font-size: 45px;
  font-family: "Montserrat Bold";
  color: #fff;
}

.sub-banner .cntnt h3,
.sub-banner .cntnt p {
  color: #fff;
}

.sub-banner .cntnt p a {
  color: #db4f5a;
}

/* / =============cmn-tabs========= / */
.cmn-tabs {
  padding: 20px 0px !important;
}

.cmn-tabs .colL {
  width: 30%;
  padding-right: 10px;
}

.cmn-tabs .colR {
  width: 70%;
  padding-left: 10px;
}

.tabs-lst {
  background: #f4f4f4;
  padding: 20px 0px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
}

.tabs-lst li {
  width: 100%;
}

.tabs-lst li a {
  width: 100%;
  color: #91989d;
  padding: 10px 20px 10px 45px;
  position: relative;
}

.active {
  color: #db4f5a !important;
  /* background: #fff; */
}

.tabs-lst li a:hover {
  color: #db4f5a;
  background: #fff;
}

.tabs-lst li a:before {
  position: absolute;
  content: "\e941";
  top: 0px;
  right: 0px;
  color: #37454e;
  font-family: "font-icon";
  font-size: 11px;
  font-weight: 600;
  bottom: 0;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.tabs-lst li.active a:before,
.tabs-lst li a:hover:before {
  opacity: 1;
}

.side-poster {
  margin-top: 20px;
}

.side-poster .image {
  width: 100%;
  height: 425px;
  overflow: hidden;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.side-poster .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.side-poster .cntnt {
  position: absolute;
  top: 0;
  left: 0;
  padding: 50px 50px 50px 30px;
  z-index: 1;
}

.side-poster .cntnt .immage {
  width: 35%;
  margin-bottom: 15px;
}

.side-poster .cntnt h2 {
  color: #fff;
  font-size: 30px;
}

.side-poster .cntnt p {
  font-size: 14px;
  color: #fff;
}

.cmn-tabs .colR .inner .left {
  width: 50%;
  padding-right: 10px;
}

.cmn-tabs .colR .inner .right {
  width: 50%;
  padding-left: 10px;
}

/* .cmn-tabs p{
   color: #2E2E2E;
 } */
.cmn-tabs h4 {
  font-size: 24px;
  color: #2e2e2e;
}

.full-txt {
  margin: 20px 0px;
  width: 100%;
}

.cmn-tabs .colR .inner:nth-child(3) {
  flex-direction: row-reverse;
}

.cmn-tabs .colR .inner:nth-child(3) .left {
  padding-right: 0px;
  padding-left: 10px;
}

.cmn-tabs .colR .inner:nth-child(3) .right {
  padding-right: 10px;
  padding-left: 0px;
}

.donate-cta {
  background: #f5f5f5;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  padding: 30px;
  position: relative;
}

.donate-cta ._colL {
  width: 30%;
  padding-right: 20px;
}

.donate-cta ._colR {
  width: 70%;
  padding-left: 40px;
}

.donate-cta ._image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.donate-cta ._image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.donate-cta ._inner p {
  font-size: 14px;
  margin-bottom: 0;
}

.donate-cta ._inner .bTn {
  margin-top: 15px;
  gap: 10px;
}

.donate-cta ._inner h3 {
  font-size: 28px;
  color: #2e2e2e;
}

.donate-cta .ab_image {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 115px;
  overflow: hidden;
}

.donate-cta .ab_image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

/* / ====================Corporate Page================ / */
.corporate-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.become_partner {
  padding-bottom: 0 !important;
}

.become_partner .coll {
  width: 50%;
}

/* / ====================Fund Raiser================ / */
.fund_raiser .flexRow {
  margin: -15px -25px;
}

.fund_raiser .flexRow:not(:nth-last-child(1)) {
  margin-bottom: 50px;
}

.fund_raiser .flexRow:nth-child(even) {
  flex-flow: row-reverse;
}

.fund_raiser .flexRow>.col {
  width: 50%;
  padding: 15px 25px;
  align-self: center;
}

.fund_form .coll {
  width: 50%;
  padding: 20px;
}

/* / ====================Donate Now page ================ / */
.donate_now {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.donate_now-sec .donate_now-flex {
  margin-top: -270px;
}

.donate_now-sec .donate_now-flex .colL {
  width: 45%;
}

.donate_now-sec .donate_now-flex .colR {
  width: 55%;
  padding: 20px;
}

.donate_now-sec .formRow {
  margin-top: 50px;
}

.donate_btn {
  min-width: 120px;
  height: 46px;
  background: #fafafa;
  color: #000;
  font-size: 14px;
  font-family: "Montserrat Medium";
  text-align: center;
  border: solid 1px #d0d0d0;
  border-radius: 5px;
}

.donate_btn_active {
  background: #ffe3e4 !important;
  color: #e93037 !important;
  border: solid 1px #e93037 !important;
}



.donateBtn {
  gap: 25px;
}

.caught .flexRow {
  margin: -15px;
}

.caught .flexRow>.col {
  width: 33.3334%;
  padding: 15px;
}

.caught .workBlk {
  height: 100%;
}

.caught .flexRow>.col .image:hover img {
  transition: all 0.5s ease-in-out;
  transform: scale(1.2);
}

.caught .workBlk>.image {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}

.caught .workBlk>.txt {
  padding: 25px 0 0;
  width: 80%;
  margin: 0 auto;
}

.workBlk .webBtn {
  margin-top: 10px;
}

.caught .content {
  width: 1000px;
  margin: 0 auto 60px;
}

.impactt {
  background-image: url(./assets/images/map_bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.impactt:before {
  position: absolute;
  content: "";
  height: 25%;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
}

.impactt .col {
  width: calc(100% / 3);
  padding: 10px;
}

.impactt .col .inner {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 35px;
  border-radius: 10px;
  height: 100%;
}

.impactt .col .inner .webBtn {
  background: #fff;
}

.impactt .col:nth-child(1) .inner {
  background-image: url(./assets/images/impact1.png);
}

.impactt .col:nth-child(2) .inner {
  background-image: url(./assets/images/impact2.png);
}

.impactt .col:nth-child(3) .inner {
  background-image: url(./assets/images/impact3.png);
}

.impactt .col:nth-child(1) .inner .webBtn {
  color: #ce3652;
}

.impactt .col:nth-child(2) .inner .webBtn {
  color: #3c9dcb;
}

.impactt .col:nth-child(3) .inner .webBtn {
  color: #d59435;
}

/* / ====================Donate form================ / */
.donate_pay .imagge {
  position: relative;
}

.donate_pay .text_image {
  position: absolute;
  bottom: 10%;
  left: 8%;
  right: 0;
  width: 75%;
}

.flex-payment {
  gap: 20px;
}

.flex-payment .txtBox {
  width: 230px;
  align-items: center;
  padding: 0 40px;
  height: 54px;
  display: flex;
  justify-content: center;
}

.flex-payment .txtBox img {
  width: 110px;
}

.donate_pay ul {
  flex: 1;
  justify-content: flex-end;
  list-style-type: none;
}

.donate_pay ul li {
  padding: 0px 3px;
}

.donate_pay .innerCardField {
  position: relative;
  margin-bottom: 10px;
}

.donate_pay .innerCardField img {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 20px;
}

.donate_pay .flex3 .col1 {
  width: 40%;
  padding-right: 20px;
}

.donate_pay .flex3 .col2 {
  width: 30%;
  padding-right: 10px;
}

.donate_pay .flex3 .col3 {
  width: 30%;
  padding-left: 10px;
}

.innerCardField .info {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
}

.innerCardField .info .infoTip {
  position: absolute;
  z-index: 10;
  color: #222222;
  width: 160px;
  top: 37px;
  left: -115px;
  background: #ffffff;
  border: 1px solid #f1f1f1;
  text-align: justify;
  -moz-text-align-last: center;
  text-align-last: center;
  font-size: 12px;
  padding: 5px 10px 5px;
  -webkit-box-shadow: rgb(0 0 0 / 15%) 2px 2px 6px 0px;
  box-shadow: rgb(0 0 0 / 15%) 2px 2px 6px 0px;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.innerCardField .info .infoTip:before {
  position: absolute;
  content: "";
  left: 94px;
  top: -3px;
  border-top: 8px solid #ffffff;
  border-left: 8px solid #ffffff;
  -webkit-box-shadow: #ffffff 1px -1px 0px;
  box-shadow: #ffffff 1px -1px 0px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.innerCardField .info:hover .infoTip {
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* / ====================pop up================ / */
.popCntnt {
  width: 600px;
  margin: 0px auto 30px;
}

.shopFlex .colL {
  width: 45%;
  padding-right: 15px;
}

.shopFlex .colR {
  width: 55%;
  padding-left: 15px;
}

.shopFlex .colL .inner {
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 15px;
}

.shopFlex .colR .txtGrp {
  margin-bottom: 40px;
}

.radioSizeLst {
  margin-bottom: -10px;
}

.shopFlex .colR h4 {
  font-size: 21px;
  color: #2a2a2a;
  margin-bottom: 13px;
}

.shopFlex .colR p {
  line-height: 10px;
}

.radioSizeLst .innerCol {
  width: 100%;
  flex: 1;
  padding: 10px;
}

.radioSizeLst .innerCol label {
  width: fit-content;
  padding: 15px;
  color: #000;
}

.radioLst .innerCol label {
  height: 40px;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d0d0d0;
  width: 100%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  color: #6e6e6e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
}

.qtyFlex {
  width: 50%;
}

.qtyFlex .qtyBlk {
  width: 52%;
  padding-right: 10px;
}

.qtyFlex .priceBlk {
  width: 48%;
  padding-left: 10px;
}

.qtyFlex .priceBlk .txtBox {
  width: 100%;
  padding: 10px;
  color: #c83945 !important;
}

.qtyBtn {
  display: flex;
  align-items: center;
  border: 1px solid #c4c4c4;
  border-radius: 6px;
  overflow: hidden;
}

.qtyBtn .readBtn {
  padding: 0px;
  font-size: 22px;
  min-width: 30px;
  height: 44px;
  line-height: 1;
  background: #fff;
  color: #707070 !important;
  border: none;
}

.qtyBtn .qty {
  background: #ffffff;
  width: 42px;
  color: #c83945 !important;
  border: 1px solid #ddd;
  padding: 0px 3px;
  font-size: 16px;
  text-align: center;
  line-height: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  height: 44px;
  border-top: none;
  border-bottom: none;
}

.smlPera {
  margin-top: 8px;
}

.infoPera .infoImg {
  width: 15px;
  height: 15px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #c83945;
  margin-top: 2px;
}

.infoPera .infoImg img {
  object-fit: scale-down;
}

.infoPera span {
  flex: 1;
  margin-left: 10px;
  font-size: 13px;
  text-transform: uppercase;
  color: #6e6e6e;
}

.infoPera span strong {
  color: #00000b;
}

.infoPera {
  margin-top: 6px;
}

/* / ====================Thankyou================ / */
.thank_you {
  background: url(./assets/images/thank_you.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.thanksMsg {
  width: 600px;
  margin: 100px auto 150px;
  text-align: center;
}

.thanksMsg .thanksIcon {
  width: 100px;
  height: 100px;
  margin: 0px auto 30px;
}

/* / ====================fact-page================ / */
.fact-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.feature-news h4 {
  font-size: 24px;
  color: #2e2e2e;
  margin-bottom: 20px;
}

.feature-news {
  margin-top: 40px;
}

.feature-news .play-list {
  margin: -10px;
}

.feature-news .col {
  width: 25%;
  padding: 10px;
}

.play-list .image {
  position: relative;
  overflow: hidden;
  padding-bottom: 56%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-bottom: 15px;
}

.play-list .image>img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.play-list .image .videoBtn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: rgb(0 0 0 / 58%);
  color: #ffffff;
  font-size: 28px;
  margin: -29px auto 0;
  line-height: 1;
  cursor: pointer;
  border-radius: 75%;
  transition: all linear 0.5s;
  border: 2px solid #ddd;
  z-index: 5;
}

.play-list .image .videoBtn.fi-play:before {
  padding-left: 6px;
}

.feature-news .col h5 {
  margin-bottom: 5px;
}

.feature-news .col h5 a {
  font-family: "Montserrat SemiBold";
  color: #2e2e2e;
}

.small-category {
  font-size: 12px;
}

.small-category span {
  color: #6e6e6e;
  position: relative;
  padding-right: 20px;
}

.small-category span:before {
  position: absolute;
  content: "";
  top: 9px;
  right: 8px;
  width: 3px;
  height: 3px;
  background-color: #6e6e6e;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  overflow: hidden;
}

.small-category span:last-child {
  padding-right: 0;
}

.small-category span:last-child:before {
  display: none;
}

.fact-tab {
  padding-bottom: 60px !important;
}

/* / =======================sex-trafficking-page============= / */
.sex-trafficking-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-wide-image {
  margin-bottom: 20px;
}

.cta-boxes {
  margin: 0 -10px;
}

.cta-boxes ._col {
  width: 50%;
  padding: 10px;
}

.cta-boxes ._col ._inner {
  background: #e0e0e03d;
  padding: 40px 25px;
  height: 100%;
}

.cta-boxes ._col ._inner h5 {
  font-size: 21px;
  color: #2e2e2e;
  text-transform: uppercase;
}

.cta-boxes ._col ._inner p:not(:last-child) {
  margin-bottom: 0;
}

.cta-boxes ._col ._inner .address-line {
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.cta-boxes ._col ._inner p:last-child a {
  text-decoration: underline;
}

/* / ============policy-page============ / */
.policy-page {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.custom-tabs {
  margin-top: 30px;
}

.custom-tabs .nav-tabs {
  border-bottom: none;
  background: #f4f4f4;
  padding: 10px;
  display: flex;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.custom-tabs .nav>li>a {
  font-family: "Montserrat SemiBold";
  padding: 6px 5px;
  width: fit-content;
  margin: 0px auto;
  color: #37454e;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid transparent;
}

.custom-tabs .nav-tabs>li.active>a,
.custom-tabs .nav>li>a:hover,
.custom-tabs .nav-tabs>li.active>a:focus,
.custom-tabs .nav-tabs>li.active>a:hover {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #e93037;
  color: #e93037;
  cursor: ponter;
}

.custom-tabs .nav>li {
  flex: 1;
}

.custom-tabs .tab-content {
  padding-top: 10px;
  margin-bottom: 20px;
}

.custom-tabs .tab-content ul li {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
}

.custom-tabs .tab-content ul li:before {
  position: absolute;
  content: "";
  top: 6px;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #db4f5a;
}

.faqLst>.faqBlk {
  position: relative;
  display: block;
  background: #ffffff;
  padding: 20px 0;
  margin-bottom: 15px;
  border-radius: 10px;
  transition: all ease 0.5s;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.199);
}

.faqLst>.faqBlk h5 {
  position: relative;
  padding: 0 25px 0 20px;
  margin: 0;
  cursor: pointer;
  transition: all ease 0.5s;
  z-index: 2;
  color: #2e2e2e;
}

.faqLst>.faqBlk h5:after {
  content: "\e93d";
  position: absolute;
  top: 5px;
  right: 20px;
  font-size: 70%;
  font-family: "font-icon";
  font-weight: bold;
  color: #2e2e2e;
}

.faqLst>.faqBlk.active h5:after {
  content: "\e943";
}

.faqLst>.faqBlk .txt {
  display: none;
  color: #2e2e2e;
  margin: 0 0 -20px;
  padding: 20px 25px;
}

.last-link {
  margin-top: 30px;
}

.last-link .link-icon {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background: #37454e;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
}

.last-link .cntnt {
  flex: 1;
  margin-left: 20px;
}

.last-link .cntnt h5 {
  font-size: 16px;
  color: #2e2e2e;
  margin-bottom: 0px;
}

.last-link .cntnt a {
  color: #29747a;
  font-size: 16px;
}

/* / ===========helpline====================** / */
.helpline {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.helpline .cntnt {
  margin-top: -60px;
}

/* /* =====================events=========================== / */
.event-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.event-banner .colL {
  width: 60%;
  padding-right: 20px;
  align-self: center;
}

.event-banner .colR {
  width: 40%;
}

.event-banner .colR form {
  background: #fff;
  box-shadow: 0px 3px 30px rgb(0 0 0 / 13%);
  padding: 32px 25px;
  border-radius: 12px;
  min-width: 400px;
}

.event-banner .colR .bTn.formBtn {
  margin-top: 60px;
}

.event-banner .colR .bTn.formBtn,
.event-banner .colR .webBtn {
  width: 100%;
}

.event-banner .contnt h1 {
  font-size: 45px;
  font-family: "Montserrat Bold";
  color: #fff;
}

.event-banner form p {
  color: #000;
}

.event-banner p {
  color: #fff;
}

.event .itmBlk {
  display: flex;
  padding: 12px;
  border-radius: 8px;
  box-shadow: rgb(126 126 126 / 25%) 0px 1px 10px 0;
}

.event .itmBlk:not(:nth-last-child(1)) {
  margin-bottom: 20px;
}

.event .itmBlk .ico {
  position: relative;
  width: 250px;
  min-width: 120px;
  border-radius: 7px;
  margin-right: 20px;
  box-shadow: 0 5px 20px rgb(18 15 56 / 10%);
}

.event .itmBlk>.txt .view {
  display: flex;
  font-size: 12px;
  margin: -5px 0 10px;
  gap: 12px;
}

.event .itmBlk>.cta .bTn {
  gap: 15px;
}

.event .itmBlk>.cta {
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  max-width: 200px;
  margin-left: 20px;
}

.event .itmBlk>.txt {
  padding: 12px;
  display: flex;
  flex-flow: column;
  flex: 1;
}

.event .itmBlk .txt ul li {
  font-family: "Montserrat Medium";
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  gap: 8px;
}

.event .itmBlk .txt ul li .f_icon {
  /* display: flex;
  align-items: center;
  margin-right: 10px; */
  width: 15px;
}

/* /* =====================helpline-sec=========================== / */
.helpline-sec {
  padding-top: 0 !important;
}

.help-flex {
  margin: -10px;
  margin-top: -65px;
}

.help-flex .col {
  width: calc(100% / 3);
  padding: 10px;
}

.help-flex .col .inner {
  background: #fff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.13);
  padding: 15px;
  height: 110px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.help-flex .col .inner .help-icon {
  background: #fff7f9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
}

.help-flex .col .inner .help-icon img {
  height: 100%;
}

.help-flex .col .inner .cntnt {
  flex: 1;
  margin-left: 15px;
}

.help-flex .col .inner .cntnt h5 {
  color: #004047;
  font-size: 13px;
  margin-bottom: 5px;
}

.help-flex .col .inner .cntnt a {
  color: #db4f5a;
  font-size: 15px;
}

.help-flex .col .inner .cntnt span {
  color: #db4f5a;
  font-size: 15px;
}

.center-cntnt {
  text-align: center;
  margin: 40px 0px;
}

.center-cntnt p {
  font-size: 19px;
  color: #1f1f1f;
  margin-bottom: 0px;
}

.flex-box-help {
  border-radius: 10px;
  overflow: hidden;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.flex-box-help .col {
  width: 50%;
}

.flex-box-help .col .image {
  width: 100%;
  height: 390px;
  overflow: hidden;
}

.flex-box-help .col .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.flex-box-help .col .inner {
  background: #f7f7f7;
  height: 390px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 30px;
}

.flex-box-help .col .inner h4 {
  color: #2e2e2e;
  font-size: 24px;
  margin-bottom: 20px;
}

.flex-box-help .col .inner ul li {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 16px;
}

.flex-box-help .col .inner ul li:before {
  position: absolute;
  content: "";
  top: 9px;
  left: 0;
  width: 5px;
  height: 5px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #db4f5a;
}

.search-box {
  background: #f7f7f7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  padding: 50px 30px;
  margin-top: 50px;
}

.search-box .colL {
  width: 60%;
}

.search-box .colR {
  width: 40%;
  padding-left: 70px;
  align-self: center;
}

.search-box .colL h4 {
  font-size: 22px;
  color: #2e2e2e;
}

.search-box .colL p {
  color: #1f1f1f;
}

.search-box .colR .txtBox {
  padding-right: 110px;
  border: 1px solid transparent;
}

.search-box .colR .webBtn {
  position: absolute;
  top: 0;
  right: 0;
  box-shadow: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.search-box .colR .txtBox::placeholder {
  color: #7e7f81;
}

.help-resources {
  margin-top: 50px;
}

.help-resources h1 {
  font-size: 45px;
  color: #2e2e2e;
  margin-bottom: 30px;
}

.help-resources ul li {
  margin-bottom: 25px;
}

.help-resources p a {
  color: #db4f5a;
}

.help-resources p {
  color: #1f1f1f;
}

.help-resources h4 {
  font-size: 20px;
  color: #2e2e2e;
  margin-bottom: 5px;
}

/* / ============= Rescue Story ========= / */
.rescue_stroy {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.rescue-sec {
  padding-top: 0 !important;
}

.rescue-flex {
  margin: -10px;
  margin-top: -50px;
  color: #fff;
  background: #37454e;
  padding: 30px 50px;
}

.rescue-flex .text {
  flex: 1;
}

.rescue-flex p {
  color: #fff;
  width: 600px;
}

.rescue-flex .bTn {
  align-self: center;
}

/* / ============= Project unite ========= / */
.project-unite {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* / ============= cases ========= / */

.cases {
  padding-top: 0 !important;
}

.cases .flexRow {
  margin: 60px -25px;
}

.cases .flexRow:not(:nth-last-child(1)) {
  margin-bottom: 50px;
}

.cases .flexRow:nth-child(odd) {
  flex-flow: row-reverse;
}

.cases .flexRow:nth-child(even) {
  flex-flow: row;
}

.cases .flexRow>.col p {
  color: #6e6e6e;
}

.cases .flexRow>.col {
  width: 50%;
  padding: 15px 30px;
}

.cases .image {
  width: 500px;
}

.cases ul li {
  display: flex;
  margin-bottom: 5px;
  gap: 5px;
  color: #000;
}

.cases ul li:last-child {
  margin-bottom: 30px;
}

.cases .vidBlk {
  width: 500px;
}

.rescue .map .cntntt {
  margin: 30px auto;
}

.map p:last-child a {
  text-decoration: underline;
}

/* / ============= Share Story ========= / */
.share_stroy {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.share-sec {
  padding: 0 !important;
}

.share-sec .share-flex {
  margin: -10px;
  margin-top: -60px;
  background: #fff;
  box-shadow: 0px 0px 13px #82828261;
  padding: 20px;
  border-radius: 15px;
}

.share-sec .share-flex .colL {
  width: 40%;
  padding-right: 20px;
}

.share-sec .share-flex .colR {
  width: 60%;
  padding-left: 20px;
}

.info_photo {
  background: #f7f5f5;
  padding: 20px;
}

.share-sec .bTn {
  gap: 15px;
}

.share-sec .bTn p {
  flex: 1;
  align-self: center;
}

.starters .cntnt_starters {
  background: #f7f7f7;
  padding: 40px;
}

.starters .cntnt_starters ul li {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  font-family: "Montserrat Medium";
}

.starters .cntnt_starters ul li:before {
  position: absolute;
  content: "";
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  background-color: #db4f5a;
}

.share_stroy .map .cntntt {
  margin: 50px auto;
}

/* / ============= Sponsorship ========= / */
.sponsorship {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

[sponsorship] .sub-banner:before {
  opacity: 1;
}

.lindsay .col {
  padding: 20px;
  width: calc(100% / 2);
  align-self: center;
}

.whiteLbl {
  position: relative;
  background-color: #fff;
  z-index: 1;
  padding: 10px 20px;
  box-shadow: 0px 0px 8px #3333330f;
  margin-bottom: 10px;
}

.whiteLbl .smImage {
  width: 70px;
  overflow: hidden;
}

.whiteLbl .smImage img {
  width: 100%;
  object-fit: contain;
  object-position: center;
}

.whiteLbl .cntnt {
  flex: 1;
  align-self: center;
  padding-left: 5px;
}

.whiteLbl .cntnt h3 {
  margin-bottom: 0px;
  text-transform: uppercase;
  color: #c83945;
}

.whiteLbl .cntnt h3:nth-child(1) {
  font-family: "Montserrat Black";
}

.lindsay {
  padding-bottom: 0 !important;
}

.lindsay .logo-lindsay {
  width: 300px;
}

.warrior {
  background: url(./assets/images/bg-gurl.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: left;
}

.warrior .contain {
  width: 100%;
}

.warrior .cntnt p {
  color: #fff;
  margin-bottom: 70px;
}

.warrior .cntnt {
  width: 600px;
  /* height: 550px; */
  padding: 70px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.warrior .cntnt .bTn .lightBtn img {
  margin-right: 10px;
}

/* =============cropSec======== */
.cropSec_home .colL {
  width: 45%;
  padding-right: 20px;
}

.cropSec_home .colR {
  width: 55%;
  padding-left: 20px;
  align-self: center;
}

.cropSec_home .colR .image {
  width: 300px;
}

.playerBlk {
  background-color: #333333;
  color: #fff;
  border-radius: 5px;
  padding: 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  /* margin-top: 130px;
	padding-bottom: 0;
	margin-right: 50px; */
}

.playerTitle {
  flex: 1;
  margin-left: 13px;
}

.playerImg {
  /* width: 210px;
	overflow: hidden;
	margin-top: -100px;
	margin-left: -10px; */
}

.playerImg img {
  width: 100%;
  object-fit: contain;
}

.playerTitle h4 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 5px;
}

.playerTitle h5 {
  font-size: 12px;
  color: #a1a4b2;
  font-family: "Montserrat Regular";
}

.playerBlk audio {
  margin-top: 5px;
  width: 100%;
}

audio::-webkit-media-controls-panel {
  background-color: rgb(255, 255, 255);
  color: rgb(66, 66, 66);
}

audio::-webkit-media-controls-play-button {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  box-shadow: 0px 0px 17px #00000061;
  margin-right: 10px;
}

.muzik {
  background-image: url(./assets/images/muzikbg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.muzik .image {
  width: 300px;
  margin: 40px auto;
}

.muzik h1,
.muzik p {
  color: #fff;
}

/* NEW CSS 21 DEC */
/* ======================national-directory================================== */
.national-directory {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.national-directory .contain {
  width: 100%;
  height: 100%;
}

.national-directory .colL {
  width: 68%;
  padding-right: 20px;
}

.national-directory .colR {
  width: 32%;
  align-self: center;
}

.national-directory .colL .cntnt {
  width: 100%;
  text-align: left;
}

.national-directory .colR .col {
  width: 100%;
}

.national-directory .colR .col .inner {
  background: transparent;
  padding: 0;
  height: auto;
}

.national-directory .colR .col .inner .cntnt {
  width: 100%;
  /* margin-left: ; */
  text-align: left;
}

.national-directory .colR .col .inner .cntnt h5 {
  color: #fff;
}

.national-directory .colR .help-flex {
  margin-top: 0;
}

/* ===================.outer_search_blk================ */
.national-directory-search-blk {
  padding-top: 0 !important;
}

.outer_search_blk {
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.13);
  padding: 30px;
  margin-top: -55px;
}

.outer_search_blk h3 {
  color: #2e2e2e;
  margin-bottom: 20px;
}

.filter_options {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.filter_options li a {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  border: 1px solid #e2e2e2;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  padding: 7px 20px;
}

.filter_options li a span {
  align-self: center;
}

.filter_options li a .text_label {
  flex: 1;
  color: #37454e;
  font-family: "Montserrat medium";
  margin-right: 25px;
}

.filter_options li a .number_label {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #db4f5a;
  background: #db4f5a47;
}

.filter_options li a:hover,
.filter_options li.active a {
  border-color: #37454e;
}

.filter_options li a._btn {
  background: #fafafa;
}

.filter_options li a._btn .text_label {
  margin-right: 0;
}

.filter_options li:last-child {
  margin-left: 70px;
}

._form {
  width: 400px;
  margin-top: 20px;
}

._form .relative {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

._form .txtBox {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  border-color: #e2e2e2;
  flex: 1;
  height: 40px;
  padding: 17px;
}

._form .webBtn {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  height: 40px;
  box-shadow: none;
  padding: 0 40px;
}

._form .txtBox::placeholder {
  color: #37454e;
}

.service_blk {
  margin-top: 40px;
}

.service_blk .colL {
  width: 50%;
  padding-right: 10px;
}

.service_blk .colL .inner {
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  overflow: hidden;
}

.service_blk .colL .inner ul {
  height: 500px;
  overflow: auto;
}

.service_blk .colR {
  width: 50%;
  padding-left: 10px;
}

.service_blk .colR iframe {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.service_blk .colL ul li {
  display: block;
  width: 100%;
}

.service_blk .colL ul li a {
  width: 100%;
  font-size: 16px;
  color: #7e7f81;
  font-family: "Montserrat medium";
  padding: 12px 20px;
}

.service_blk .colL ul li a:hover,
.service_blk .colL ul li.active a {
  background: #ffeff3;
  color: #000;
}

.txt_blk {
  margin-top: 40px;
  background: #f7f7f7;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 20px;
}

.txt_blk p {
  color: #6c6c6c;
}

.txt_blk p a {
  color: #db4f5a;
}

/* ================.map-box================ */
.map-box {
  width: 210px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px #ddd;
  position: absolute;
  top: 10%;
  right: 10%;
}

.map-box .image {
  height: 120px;
  overflow: hidden;
  width: 100%;
}

.map-box .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.map-box .map_cntnt {
  padding: 10px;
}

.map-box .map_cntnt a {
  font-size: 11px;
  color: #db4f5a;
  font-family: "Montserrat SemiBold";
  margin-bottom: 10px;
}

.map-box .map_cntnt p {
  display: flex;
  color: #7e7e7e;
  font-family: "Montserrat medium";
  font-size: 10px;
}

.map-box .map_cntnt p span {
  align-self: center;
  margin-left: 7px;
}

.map-box .map_cntnt p:nth-child(2) {
  margin-bottom: 10px;
}

.map-box .map_cntnt p img {
  width: 14px;
  height: 20px;
}

/* ====================================current-affairs====== */
.current-affairs {
  height: 500px;
  background-image: linear-gradient(to right,
      #000000 0%,
      #00000085 51%,
      #0e0e0e85 100%);
}

.current-affairs .contain {
  width: 100%;
  height: 100%;
}

.videoBanner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.videoBanner video {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: cover;
  object-position: center;
}

/* ===========merchandise details"========= */
.detail_merchandise .flex .colL {
  width: 50%;
  padding-right: 20px;
}

.detail_merchandise .flex .colL .collL {
  width: 20%;
  padding-right: 20px;
}

.detail_merchandise .flex .colL .collR {
  width: 80%;
  padding-left: 20px;
}

.detail_merchandise .flex .colR .bTn {
  gap: 20px;
}

.detail_merchandise .flex .colR {
  width: 50%;
  padding-left: 20px;
}

.detail_merchandise .flex .colL .collL ul li {
  border: solid 1px #ededed;
  border-radius: 5px;
}

.detail_merchandise .flex .colR .coll {
  width: calc(100% / 3);
  padding-right: 15px;
}

/* / ==================== Traffik and sex page================ / */
/* =============banner========= */
.trafikBanner {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 590px;
  padding: 30px 0px;
  margin-top: -24px;
}

.trafikBanner .colL {
  width: 50%;
  padding-right: 20px;
}

.trafikBanner .colL {
  align-self: center;
}

.trafikBanner .colL h1 {
  margin-top: 0;
}

.trafikBanner .colR {
  width: 50%;
  padding-left: 20px;
}

.trafikBanner .image {
  margin-top: 60px;
  width: 100%;
  height: 550px;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  box-shadow: 0px 0px 10px #111110;
}

.trafikBanner .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.trafikBanner h1 {
  color: #e93037;
}

.trafikBanner p {
  color: #ffffff;
}

.trafikBanner .bTn {
  margin-top: 40px;
}

.trafikBanner .slider-btn {
  text-transform: uppercase;
  padding: 15px 40px;
}

/* ============================Slider traffik and sex============== */
.half-slider {
  background-color: #f7f7f7;
  padding: 100px 0px 60px;
}

.h-slider .videoBlk {
  position: relative;
  display: block;
  background: #fff;
  height: 350px;
  overflow: hidden;
}

.h-slider .videoBlk video,
.h-slider .videoBlk iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 0 !important;
  margin: auto;
  object-fit: fill;
}

.h-slider .videoBlk .videoBtn {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  background: transparent;
  color: #fff;
  font-size: 34px;
  margin: -40px auto 0;
  line-height: 1;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 75%;
  /* box-shadow: 0 17px 50px 0 rgb(0 0 0 / 19%), 0 12px 15px 0 rgb(0 0 0 / 24%), 0 17px 50px 0 rgb(0 0 0 / 19%), 0 12px 15px 0 rgb(0 0 0 / 24%); */
  transition: all linear 0.5s;
  z-index: 5;
}

.videoBtn.fa-play:before {
  padding-left: 10px;
}

.h-slider .videoBtn.fa-pause:before {
  padding-left: 0px;
}

.h-slider .videoBtn.fa-pause {
  opacity: 0;
}

.h-slider .videoBlk:hover .videoBtn.fa-pause {
  opacity: 1;
}

.half-slider .h-slider #owl-video .inner {
  width: 380px;
  height: 100%;
  position: relative;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  overflow: hidden;
  margin: 0px auto 0px;
}

.half-slider .owl-carousel .owl-stage-outer {
  overflow: unset;
}

.half-slider .h-slider #owl-video .inner .image {
  width: 320px;
  height: auto;
  overflow: hidden;
  border-radius: 4px;
  margin: 0px auto 20px;
  transform: scale(0.6);
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -o-transform: scale(0.6);
  position: relative;
}

.half-slider .h-slider #owl-video .inner .image:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #ffffff9c;
  z-index: 1;
}

.half-slider .h-slider #owl-video .owl-item.active .inner .image:before {
  opacity: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.half-slider .h-slider #owl-video .inner .txt {
  text-align: center;
  opacity: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.half-slider .h-slider #owl-video .inner .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.half-slider .h-slider #owl-video .owl-item.active .inner .image {
  width: 520px;
  transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -ms-transform: scale(0.9);
  -o-transform: scale(0.9);
}

.half-slider .h-slider #owl-video .owl-item.active .inner .txt {
  opacity: 1;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.half-slider .h-slider #owl-video .owl-item.active .inner .txt h4 {
  font-size: 20px;
  color: #172330;
  font-family: "Montserrat Medium";
  width: 510px;
  margin: 0px auto 10px;
}

.half-slider .h-slider #owl-video .owl-item.active .inner {
  width: 680px;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.half-slider .h-slider #owl-video .owl-item .inner .videoBtn {
  opacity: 0;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.half-slider .h-slider #owl-video .owl-item.active .inner .videoBtn {
  opacity: 1;
}

#owl-video {
  width: 650px !important;
  margin: 0px auto;
}

.half-slider .owl-stage {
  display: flex;
  justify-content: center;
  align-items: center;
}

.half-slider .owl-nav {
  display: inline-block;
}

.half-slider .owl-prev {
  left: 0px;
}





/* ===========imagesGrid========== */
.imagesGrid {
  padding: 90px 0px 30px;
}

.imagesGrid .flex {
  margin: 0 -10px;
}

.imagesGrid ._col {
  width: calc(100% / 3);
  padding: 10px;
}

.imagesGrid ._col .image {
  width: 100%;
  height: 280px;
  overflow: hidden;
  margin-bottom: 10px;
}

.imagesGrid ._col .image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.imagesGrid ._col p {
  font-size: 14px;
  font-weight: 100;
  line-height: 24px;
}

/* ====================aboutSec=========== */
.aboutSec {
  background-color: #fce2e9;
  padding: 60px 0px 70px;
}

.aboutSec .colL {
  width: 50%;
  padding-right: 10px;
}

.aboutSec .colR {
  width: 50%;
  padding-left: 20px;
}

.aboutSec .image {
  position: relative;
  height: 530px;
}

.aboutSec .image .outerImg {
  position: absolute;
  top: 0;
  left: 20px;
  width: 480px;
  overflow: hidden;
}

.aboutSec .image .outerImg img,
.aboutSec .image .innerImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.aboutSec .image .innerImg {
  position: absolute;
  top: 170px;
  left: -40px;
  width: 400px;
  overflow: hidden;
}

.smallInfo p {
  font-size: 13px;
  font-family: "Montserrat Medium";
  text-transform: uppercase;
  color: #c83945;
  margin-bottom: 0;
}

.smallInfo span {
  width: 15px;
  height: 15px;
  background: #c83945;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  align-self: center;
  margin-left: 10px;
}

.smallInfo span img {
  object-fit: none;
}

.aboutSec .bTn {
  margin-top: 20px;
  gap: 15px;
}

.aboutSec .bTn a {
  gap: 10px;
}

/* .aboutSec .bTn a span{
	flex: 1;
    align-self: center;
}
.aboutSec .bTn a:nth-child(1) img{
	width: 30px;
	object-fit: contain;
} */

/* .aboutSec .bTn a.emptyBtn{
	padding: 15px 27px;
	margin-left: 20px;
} */

.aboutSec .colR p small {
  /* font-size: 14px; */
  color: #00000b;
}

.aboutSec .colR h3 {
  color: #db4f5a;
  text-transform: uppercase;
  font-size: 28px;
  font-family: "Poppins SemiBold";
  margin-bottom: 20px;
}

.aboutSec .colR ul li {
  display: flex;
  width: 100%;
  background-color: #fff;
  padding: 20px 20px;
  box-shadow: 0px 0px 10px #3333330f;
}

.aboutSec .colR ul li:not(:last-child) {
  margin-bottom: 15px;
}

.aboutSec .colR ul li p {
  flex: 1;
  margin-left: 20px;
  color: #00000b;
}

.aboutSec .colR ul {
  padding-inline-start: 0px;
}

.aboutSec .colR ul li span {
  color: #fddddf;
  font-family: "Poppins SemiBold";
  font-size: 75px;
  line-height: 1;
}

/* =================partner========== */
.partner {
  padding: 50px 0px;
  background: url(./assets/images/number.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.partner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.473);
}

.partner .container {
  position: relative;
  z-index: 1;
}

.partner h2 {
  color: #ffffff;
}

.partner .flex {
  margin-top: 40px;
}

.partner .col {
  width: 25%;
  padding: 10px;
}

.partner .col .image {
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.partner .col .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.about .heading_title span {
  color: #00000b;
}

.about .heading_title {
  font-family: "Poppins Semibold";
}

.subTitle {
  font-family: "Poppins Semibold";
  font-size: 16px;
  text-transform: uppercase;
  color: #f32c62;
}

/* =============cropSec======== */
.cropSec .flex {
  justify-content: center;
  text-align: center;
}

.cropSec .flex .involedSocail {
  justify-content: center;
}

.cropSec .flex .involedSocail .col {
  flex-grow: 1;
}

.cropSec {
  padding: 100px 0px !important;
  background: url(./assets/images/anti.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  clip-path: polygon(0 0, 100% 9%, 100% 91%, 0 100%);
}

.cropSec .colL {
  width: 55%;
  padding-right: 10px;
}

.cropSec .colR {
  width: 45%;
  padding-left: 10px;
  align-self: center;
}

.cropSec .colR .image {
  width: 100%;
  overflow: hidden;
}

.cropSec .colR .image img {
  width: 100%;
  object-position: center;
  object-fit: contain;
}

.cropSec h2 {
  font-size: 35px;
  font-weight: 400;
  color: #ffffff;
  line-height: 48px;
  text-transform: capitalize;
  margin-bottom: 20px;
}

.cropSec h2 span {
  color: #f32c62;
}

.cropSec p {
  color: #fff;
  font-size: 15px;
}

.cropSec .playerBlk {
  background-color: #ffffff;
  color: #000;
  margin-top: 130px;
  margin-right: 50px;
}

.cropSec .playerImg {
  width: 210px;
  overflow: hidden;
  margin-top: -100px;
  margin-left: -10px;
}

.cropSec .playerTitle h4 {
  color: #29292a;
}

audio::-webkit-media-controls-panel {
  background-color: rgb(255, 255, 255);
  color: rgb(66, 66, 66);
}

audio::-webkit-media-controls-play-button {
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  box-shadow: 0px 0px 17px #00000061;
  margin-right: 10px;
}

.involved {
  background-color: #fce2e9;
  padding: 50px 0px 50px;
  /* margin-bottom: 25px; */
  position: relative;
}

.involved .ab_image {
  position: absolute;
  right: 0;
  width: 130px;
  bottom: 0;
  top: auto !important;
  overflow: hidden;
}

.involved .ab_image img {
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: contain;
}

.involved .heading_title span {
  color: #00000b;
}

.involved .hire_div {
  width: 50%;
  margin: 0 auto;
  text-align: center;
}

.involved .hire-description p {
  color: #7f7f81;
  font-size: 13px;
}

.involved .hire-description {
  padding-bottom: 0;
}

.involved .newSmImage {
  overflow: hidden;
  height: 330px;
  box-shadow: 0px 0px 5px #333;
  border-radius: 2px;
}

.involved form {
  margin-top: 30px;
}

.involvedForm {
  background: #fff;
  border-radius: 5px;
  padding: 5px;
  padding-bottom: 5px !important;
  border: 1px solid #d0d0d0;
  display: flex;
  align-items: center;
}

.involvedForm .txtBox,
.involvedForm .slider-btn {
  height: 37px;
}

.involvedForm .txtBox {
  border: none;
}

.involvedForm .slider-btn {
  padding: 8px 20px;
  border-radius: 5px 5px 5px 5px;
  -webkit-border-radius: 5px 5px 5px 5px;
  -moz-border-radius: 5px 5px 5px 5px;
  -ms-border-radius: 5px 5px 5px 5px;
  -o-border-radius: 5px 5px 5px 5px;
}

.involedSocail .col {
  width: calc(100% / 4);
  /* padding: 10px; */
  flex-grow: unset;
}

.involedSocail .col ._ico {
  -webkit-box-shadow: 0 10px 40px rgb(0 0 0 / 8%);
  box-shadow: 0 10px 40px rgb(0 0 0 / 8%);
  background-color: #ffffff;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 30px auto 10px;
}

.involedSocail .col ._ico img {
  width: 35px;
  height: auto;
}

.involedSocail .col .inner:after {
  display: none;
}

/*===============join=======*/
#join {
  background: url(./assets/images/number.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.blogSec .secHeading h2 {
  color: #fff;
}

#join .secHeading h2 {
  color: #fff;
}

#join .secHeading h2 span {
  font-family: "Poppins Semibold";
}

#join .flex {
  margin: 40px -10px;
  display: flex;
  flex-wrap: wrap;
}

#join .col {
  width: calc(100% / 4);
  padding: 10px;
}

#join .col .inner {
  -webkit-box-shadow: 0 3px 30px rgba(0, 0, 0, 0.08);
  box-shadow: 0 3px 30px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
  background-color: #ffffff;
  overflow: hidden;
  text-align: center;
  height: 323px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
}

#join .col .inner h3 {
  color: #00000b;
  font-size: 23px;
  line-height: 30px;
  padding: 0px 50px;
  margin-bottom: 30px;
}

#join .col .inner h1 {
  color: #fc2c62;
  font-size: 55px;
  font-family: "Poppins SemiBold";
}

#join .col:first-child h3 {
  padding: 0px 30px;
}

.joinSubHeading h3 {
  color: #7f7f81;
}

.joinSubHeading h1 {
  color: #fc2c62;
  font-size: 55px;
  margin-top: 30px;
  font-family: "Poppins SemiBold";
}

.joinSubHeading h4 {
  color: #7f7f81;
  font-size: 17px;
  text-transform: uppercase;
}

.joinDonateBtn {
  margin-top: 60px;
  margin-bottom: 20px;
}

.joinDonateBtn a {
  text-transform: uppercase;
  padding: 15px 30px;
}

/* ===========merchandise"========= */
.merchandise {
  background: url(./assets/images/productsbg.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.merchandise_products .flexRow>.col {
  width: calc(100% / 4);
  padding: 10px;
}

.merchandise_products .flexRow>.col .inner .image {
  background: #f7f7f7;
}

.merchandise_products .flexRow>.col .inner {
  height: 100%;
  border: solid 1px #ededed;
  border-radius: 5px;
  overflow: hidden;
}

.merchandise_products .txt {
  padding: 25px;
}

/* ===========playing_listing"========= */
.playing_listing {
  /* padding-top: 0!important; */
  background: #000;
}

.playing_listing .feature-news {
  margin-top: 0px;
}

.playing_listing .feature-news .col h5 a {
  color: #ffffff;
}

.play-time {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: #000000ad;
  color: #fff;
  font-size: 12px;
  font-family: "Montserrat SemiBold";
  padding: 0px 5px;
  border-radius: 3px;
}

.current_aff ._col {
  width: 20%;
}

.current_aff ._colM {
  width: 80%;
  padding: 0px 30px;
}

.current_aff ._col .feature-news .col {
  width: 100%;
}

.current_aff ._col .feature-news .col .inner h5 a {
  font-family: "Montserrat SemiBold";
}

.current_aff ._col .feature-news .col .inner h6 {
  font-size: 16px;
  color: #6e6e6e;
  font-family: "Montserrat regular";
}

.current_aff .col1 .feature-news .col .inner h6 {
  margin-top: 10px;
}

.current_aff ._col .feature-news {
  margin-top: 0;
}

.current_aff .col2 .feature-news .col .inner h5 a {
  text-transform: uppercase;
}

.current_aff .col2 .feature-news .col .inner {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.current_aff .col2 .feature-news .col:last-child .inner {
  padding-bottom: 0;
  border-bottom: none;
}

.current_aff ._col .feature-news .col .inner p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #676767;
  margin-bottom: 5px;
}

.read_more_lnk {
  color: #e93037;
  font-size: 16px;
  font-family: "Montserrat medium";
}

.current_aff ._colM .inner .image {
  margin-bottom: 10px;
}

.current_aff ._colM .inner h5 a {
  text-transform: uppercase;
  color: #020202;
  font-size: 27px;
}

.current_aff ._colM .inner p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #676767;
  font-size: 16px;
  margin-bottom: 5px;
}

.news_more {
  background: #efefef;
}

.news_more .col1 {
  width: 25%;
  padding-right: 20px;
}

.news_more .col2 {
  width: 30%;
  padding-right: 10px;
}

.news_more .col3 {
  width: 30%;
  padding-left: 10px;
}

.news_more .col .inner {
  background: #fff;
}

.news_more .col .inner ._cntnt {
  padding: 20px;
}

.news_more .col .inner ._cntnt h5 a {
  color: #020202;
  font-size: 20px;
  text-decoration: underline;
}

.news_more .col .inner ._cntnt p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  color: #676767;
  margin-bottom: 5px;
}

.news_more .col2 .inner:first-child {
  margin-bottom: 20px;
}

/* ===========Sign In page"========= */
[logon] {
  padding-top: 0;
}

.form-blk .oRLine {
  position: relative;
  text-align: center;
  margin: 30px 0 40px 0;
}

.form-blk .oRLine:before {
  position: absolute;
  content: "";
  top: 20px;
  left: 0px;
  right: 0px;
  height: 1px;
  background: #eee;
}

.form-blk .oRLine span {
  position: relative;
  padding: 6px 15px 6px;
  background: #fff;
  font-size: 17px;
}

.form-blk .haveAccount a {
  text-decoration: underline;
}

/* ==============form-page======= */
.form-blk {
  padding: 0px !important;
}

.form-blk .colL {
  width: 40%;
  max-height: 110vh;
  min-height: 100vh;
  position: relative;
  /* background-image: url(./assets/images/signin.png); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
}

.form-blk .txtBox::placeholder {
  color: #7e7f81;
  font-size: 14px;
}

.form-blk .colL .image {
  position: absolute;
  left: 0;
  right: 0;
  overflow: hidden;
  color: #fff;
  padding-left: 30px;
}

.form-blk .colL .image img {
  width: 70%;
}

.form-blk .bTn,
.form-blk .bTn a {
  gap: 15px;
}

.form-blk .colR {
  width: 40%;
  padding: 50px 100px;
  align-self: center;
}

.form-blk .colR h2 {
  margin-bottom: 30px;
  font-size: 35px;
}

/* .form-sign-up .radioLst .innerCol label {
  font-size: 12px;
  justify-content: flex-start;
} */
.form-sign-up .colR {
  width: 50%;
}

/* .form-sign-up .colL {
  background-image: url(./assets/images/signup.png);
} */
.form-sign-up .radioSizeLst .innerCol {
  width: auto;
  flex: none;
}

.form-sign-up .privacy a {
  color: #000;
}

/* ===========subscribe popup========= */
.sub_pop .subCntnt {
  background-image: url(./assets/images/popbg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0px auto 30px;
  border-radius: 6px;
  color: #fff;
  padding: 50px 0;
}

.sub_pop span {
  text-decoration: line-through;
}

.sub_pop em {
  color: #db4f5a;
}

.subcontent p.sml {
  margin-top: 10px;
  color: #aaa;
}

/*
|----------------------------------------------------------------------
|       Shopping Cart
|----------------------------------------------------------------------
*/

/*_____ shopTable _____*/

/* #cart .cart-heading {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 80px;
  text-align: center;
  text-transform: uppercase;
  color: #000;
  line-height: 40px;
} */
#cart .shopTable {
  margin: -20px -20px 20px;
}

.shopTable table {
  width: 100%;
}

.shopTable table tr>* {
  padding: 20px 10px;
  text-align: center;
  border-bottom: 1px solid #eee;
}

.shopTable table tr>*:nth-child(1) {
  text-align: left;
  padding-left: 20px;
}

.shopTable table thead tr>*:nth-child(2),
.shopTable table tbody tr>*:nth-child(2) {
  text-align: left;
}

.shopTable table tr>*:nth-last-child(1) {
  padding-right: 20px;
}

.shopTable table tbody tr>*:before {
  content: attr(data-title) ":";
  display: none;
  width: 120px;
  min-width: 120px;
  font-family: "semi";
}

.shopTable .pro_cart {
  display: flex;
  align-items: center;
}

.shopTable .pro_cart .ico {
  width: 46px;
  min-width: 46px;
  height: 46px;
  margin-right: 15px;
  border-radius: 0;
  box-shadow: none;
}

.shopTable .pro_cart h4 {
  font-size: 14px;
  margin-bottom: 7px;
}

.shopTable .pro_cart p {
  color: #8d9297;
  margin: 0;
}

.shopTable .price {
  font-weight: bold;
}

.shopTable .qty {
  width: 40px;
  height: 40px;
  font-weight: bold;
  padding: 0 3px;
  margin: auto;
  text-align: center;
  border: 1px solid #eee;
  border-radius: 4px;
}

.shopTable table tfoot tr>* {
  padding-top: 10px;
  padding-bottom: 10px;
}

.shopTable table tfoot tr>*:nth-child(1) {
  color: #db4f5a;
  text-align: right;
}

#cart .blk {
  border-color: #eee;
}

/*
|----------------------------------------------------------------------
|       Checkout
|----------------------------------------------------------------------
*/

#checkout {
  /* margin: -80px 0 0; */
  /* padding: 0 0 20px !important; */
  overflow: hidden;
  padding-bottom: 20px;
}

#checkout .logo {
  float: none;
  margin: 0 0 20px;
}

#checkout hr {
  border-color: #ff5274;
}

#checkout .flexRow {
  margin: -10px -50px;
}

#checkout .flexRow>.col {
  padding: 10px 50px;
}

#checkout .flexRow>.col1 {
  width: 55%;
}

#checkout .flexRow>.col2 {
  width: 45%;
}

#checkout .shopTbl {
  padding: 10px 0 0;
  border: 0;
  border-top: 1px solid;
}

#checkout .shopTbl>table tr>* {
  border: 0;
}

#checkout .policyLst {
  margin: -2px -10px;
}

#checkout .policyLst>li {
  padding: 2px 10px;
  display: inline-block;
}

#checkout [data-payment] .topHead .lblBtn {
  flex: 1;
  margin-right: 10px;
}

/*_____ smryBlk _____*/

.smryBlk {
  padding: 15px;
  border: 1px solid;
}

.smryBlk>._header {
  margin-bottom: 15px;
}

.smryBlk>._header>h5 {
  margin: 0;
}

.smryBlk .priceLst>li {
  display: flex;
  padding-bottom: 10px;
}

.smryBlk .priceLst>li:nth-child(2) {
  color: #ff5274 !important;
}

.smryBlk .priceLst>li>div {
  flex: 1;
}

.smryBlk .priceLst>li>div:nth-last-child(1) {
  flex: auto;
  text-align: right;
}

.smryBlk .priceLst>li:nth-last-child(1) {
  /* color: #f53737; */
  padding-top: 15px;
  padding-bottom: 0;
  border-top: 1px solid;
}

/*
|----------------------------------------------------------------------
|       Checkout Form
|----------------------------------------------------------------------
*/
#checkout .formRow>[class*="col-"],
#checkout.formRow>[class^="col-"] {
  padding: 10px;
}

#checkout .txtGrp {
  position: relative;
}

#checkout .txtGrp:not(.lblBtn)>label {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  min-height: 46px;
  color: #8d9297;
  line-height: 1;
  margin: 0;
  margin-left: 13px;
  pointer-events: none;
  transition: all ease 0.5s;
  z-index: 1;
}

#checkout h4 {
  color: #000;
}

label {
  display: block;
  font-weight: normal;
  cursor: pointer;
}

#checkout .txtGrp label+.txtBox:not(button) {
  padding: 16px 12px 6px;
}

#checkout .txtBox {
  display: block;
  width: 100%;
  height: 46px;
  background: #fff;
  text-align: left;
  padding: 6px 12px;
  border: 1px solid #65388b;
  border-radius: 0;
  outline: none !important;
  transition: all ease 0.5s;
}

.txtGrp:not(.lblBtn)>label.move {
  font-size: 10px;
  transform: translateY(-12px);
}

#checkout .topHead {
  display: flex;
  align-items: center;
}

#checkout ul>li {
  color: rgb(0 0 0 / 82%);
}

#checkout [data-payment] .topHead .lblBtn {
  flex: 1;
  margin-right: 10px;
}

#checkout [data-payment] .topHead .lblBtn>input[type="radio"] {
  margin-top: 0;
}

#checkout .cardLst {
  margin-bottom: -4px;
}

#checkout .cardLst>li {
  display: inline-block;
}

#checkout .cardLst>li>img {
  width: 28px;
}

#checkout [data-payment] .inside {
  margin-top: 15px;
}

#checkout .blk>.hr {
  margin: 20px 0 0;
}

#checkout [data-payment]>[data-payment="paypal"]>.inside {
  display: none;
}

#checkout [data-payment]>[data-payment="paypal"] .cardLst>li>img {
  width: 62px;
}

.bTn.formBtn {
  margin-top: 20px;
}

[cart] #placed .blk .icon {
  width: 100px;
  min-width: 100px;
  height: 100px;
  margin: 0 auto 20px;
}

/* ====================service details=========== */

#story_detail .colL {
  width: 70%;
  padding-right: 20px;
}

/* #story_detail .colL .flex{
	margin: 20px 0px;
} */
/* #story_detail .colL .col{
	width: 50%;
} */
#story_detail .colL .image {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 20px 0;
}

#story_detail .colL .image img,
#story_detail .colR .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#story_detail .colR .image {
  width: 100%;
  overflow: hidden;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-bottom: 10px;
}

#story_detail .colL .col:first-child {
  padding-right: 30px;
}

#story_detail .colR {
  width: 30%;
  padding-left: 20px;
}

#story_detail .colR .inner {
  padding: 20px;
  background: #ffe4e6;
  border-radius: 10px;
}

#story_detail .colR h3 {
  font-size: 18px;
  font-family: "Poppins Regular";
  color: #000000;
  margin-bottom: 20px;
  border-bottom: 1px solid #db4f5a;
  padding-bottom: 20px;
}

#story_detail .colR ul li {
  display: block;
}

#story_detail .colL ul li {
  display: block;
  padding-left: 20px;
  position: relative;
  margin-bottom: 10px;
}

#story_detail .colL ul li:before {
  position: absolute;
  content: "";
  top: 7px;
  left: 0;
  width: 7px;
  height: 7px;
  background: #333;
}

#story_detail .sideBlk {
  position: -webkit-sticky;
  position: sticky;
  top: 10rem;
}

/* ==================LIVE TV CSS ======================= */
.liveTvBody {
  position: relative;
  min-height: 100%;
  background: #000;
  color: #fff;
  font-size: 14px;
  font-family: "Montserrat Medium";
  margin: 0;
  padding: 0;
  line-height: 1.6;
  word-break: break-word;
  transition: all ease 0.5s;
  overflow-x: hidden;
}

.liveMain {
  background-color: #000;
}

.liveMain section {
  padding: 40px 0;
}

/* ========= banner_tv ========== */

#banner_tv {
  /* margin-top: 130px; */
  padding-top: 0;
  /* padding-bottom: 0; */
}

#banner_tv .item {
  /* height: 850px; */
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}

#banner_tv .item .image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#banner_tv .item .image img {
  width: 100%;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.overlay {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Path-20101.png);
  background-size: cover;
  background-position: center;
}

/* #banner_tv .overlay{
  background-image: none;
} */
#banner_tv .overlay:after {
  position: absolute;
  content: "";
  /* top: 0; */
  height: 45%;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, black, transparent);
}

#banner_tv .content {
  max-width: 620px;
  color: #fff;
  padding-top: 180px;
  margin: 0;
  width: 100%;
  display: flex;
  flex-flow: column;
  row-gap: 2rem;
}

#banner_tv .content>*,
#banner_tv .content>div>p {
  color: #fff !important;
}

#banner_tv .content p {
  margin-bottom: 20px;
}

#banner_tv .netflix-logo {
  width: 20px;
}

#banner_tv .webBtn {
  width: fit-content;
}

#banner_tv .webBtn img {
  margin-right: 10px;
  width: 2rem;
}

#banner_tv h4 {
  align-self: center;
  padding-left: 10px;
  font-family: "Montserrat Regular";
  margin-bottom: 0px;
  letter-spacing: 6px;
  font-size: 16px;
  color: #fff;
}

#banner_tv h1 {
  margin-top: 10px;
  letter-spacing: 2px;
  color: #fff;
}

#banner_tv .webBtn {
  color: #fff !important;
}

#banner_tv p {
  color: #f9f9f9;
  margin-bottom: 20px;
}

#banner_tv .trending_shows h3 {
  color: #f9f9f9;
  font-family: "Montserrat Regular";
}

#banner_tv .trending_shows {
  padding-top: 70px;
}

#banner_tv .trending_shows .owl-carousel .item1 .overlay2,
#banner_tv .trending_shows .owl-carousel .item1 .bar,
#banner_tv .trending_shows .owl-carousel .item1 .text {
  display: none;
}

#banner_tv .item1 {
  display: block;
}

#banner_tv .flexDv {
  height: 40vw;
  /* margin-top: 9rem; */
}

#banner_tv .flexDv .contain {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#banner_tv .image1 {
  position: relative;
  /* height: 200px; */
  overflow: hidden;
  padding-bottom: 55%;
}

#banner_tv .image1 img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* #banner_tv .image1::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(to top, #000000, transparent);
  z-index: 1;
} */

#banner_tv .image1 img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

#banner_tv .image1 .channel {
  position: absolute;
  right: 10px;
  top: 10px;
  content: "";
  background-color: #2c2c2c;
  padding: 8px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  width: 60px;
  height: 47px;
}

#banner_tv .image1 .channel img {
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.overlay2 {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Rectangle-51.png);
  background-size: cover;
  background-position: center;
}

.bar {
  position: absolute;
  bottom: 14px;
  left: 0;
  content: "";
  padding: 0 20px;
  width: 100%;
}

.progress {
  background: #ebeced none repeat scroll 0 0;
  border-radius: 10px;
  height: 3px !important;
  overflow: visible;
  margin-bottom: 0;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.progress .progress-bar {
  background: #e51937 none repeat scroll 0 0;
  border-radius: 25px;
  overflow: visible;
  position: relative;
}

#banner_tv .trending_shows .text {
  margin-top: 16px;
}

#banner_tv .trending_shows .text h4 {
  color: #fff;
  position: relative;
  padding-left: 20px;
  letter-spacing: normal;
  margin-bottom: 10px;
}

#banner_tv .trending_shows .text h4:before {
  position: absolute;
  content: "";
  top: 3px;
  left: 0;
  height: 10px;
  width: 10px;
  background-color: #d31812;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

#banner_tv .trending_shows .text h5 {
  color: #707070;
  padding-left: 20px;
}

#banner_tv .owl-dots {
  margin-top: -300px;
}

#banner_tv .owl-dots .owl-dot span {
  display: block;
  width: 15px;
  height: 2px;
  background-color: #ffffff;
  opacity: 49%;
  margin: auto;
  border-radius: 50px;
  transition: all ease 0.5s;
  margin: 0 6px;
}

#banner_tv .owl-dots .owl-dot.active span {
  width: 15px;
  height: 2px;
  background-color: #ffffff;
  opacity: 1;
}

/* ========= popular_movies ========== */

#popular_movies {
  padding-top: 0;
}

#popular_movies h3 {
  color: #b2b2b2;
  font-family: "Montserrat Regular";
}

#popular_movies .text {
  margin-top: 20px;
}

#popular_movies h5 {
  color: #707070;
}

#popular_movies .item {
  display: block;
}

#popular_movies .image {
  /* height: 300px; */
  position: relative;
  padding-bottom: 55%;
  overflow: hidden;
}

#popular_movies .image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.tv-shows .image {
  /* height: 211px !important; */
  overflow: hidden;
}

.tv-shows .image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.minus {
  margin-top: -380px;
}

/* ======== movies page ========= */

.overlay3 {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Rectangle-3501.png);
  background-size: cover;
  background-position: center;
}

.sbanner_tv .image3 {
  height: 150px;
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: -20px;
}

.sbanner_tv .image3 img {
  height: 100%;
  width: unset;
  object-fit: cover;
  object-position: center;
}

.sbanner_tv .item {
  height: 600px !important;
}

/* ======== movie detail ========= */

.movie-detail {
  padding-bottom: 0 !important;
  margin-top: 0px !important;
}

.movie-detail .overlay5 {
  z-index: -1;
}

.movie-detail .item {
  height: 1050px !important;
}

.overlay4 {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Rectangle-62.png);
  background-size: cover;
  background-position: center;
}

.overlay5 {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Path-39.png);
  background-size: cover;
  background-position: center;
  /* z-index: -1; */
}

.movie-detail .inner {
  position: relative;
  margin-top: 200px;
  padding: 20px;
}

.movie-detail .inner .image2 {
  height: 452px;
  width: 300px;
  overflow: hidden;
  margin-top: -100px;
  z-index: 1;
}

.movie-detail .inner .image2 img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.movie-detail .text {
  /* max-width: 880px; */
  flex: 1;
  padding-left: 40px;
  z-index: 1;
}

.movie-detail .overlay5 {
  z-index: 0;
}

.movie-detail .text>*,
.movie-detail .text>.bTn>a {
  color: #fff !important;
}

.movie-detail .text h3 {
  font-family: "Montserrat Medium";
  color: #a8a8a8;
}

.movie-detail .text h1 {
  font-size: 60px;
  font-family: "Montserrat Bold";
}

.movie-detail .text h4 {
  font-size: 18px !important;
  font-family: "Montserrat Medium" !important;
  margin-bottom: 10px !important;
}

.movie-detail .text p {
  color: #aaaaaa;
}

.movie-detail .bTn {
  margin-top: 100px;
  gap: 15px;
}

.casting {
  margin-top: 40px;
  position: relative;
  z-index: 1;
}

.casting .col .inner1 {
  display: flex;
}

.casting h2 {
  font-size: 30px;
  color: #b2b2b2;
}

.casting .col {
  margin-top: 20px;
}

.casting .col .inner1 .icon {
  height: 120px;
  width: 120px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.casting .col .inner1 .icon img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.casting .col .inner1 .text1 {
  align-self: center;
  padding-left: 20px;
}

.casting .col .inner1 .text1 h3 {
  font-size: 18px;
  font-family: "Montserrat Medium" !important;
  color: #fff !important;
}

.casting .col .inner1 .text1 h5 {
  font-size: 16px;
  color: #707070;
  font-family: "Montserrat SemiBold" !important;
}

.casting .flex {
  margin: 0 -30px;
}

.casting .flex .col {
  padding: 0 30px;
}

.related-movies {
  margin-top: -130px;
}

/* ======== live tv ========= */

.overlay6 {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Rectangle-321-copy-3.png);
  background-size: cover;
  background-position: center;
}

.live_tv h3 {
  font-family: "Montserrat SemiBold" !important;
}

.live_tv h2 {
  font-family: "Montserrat Bold" !important;
  color: #aaaaad;
}

.live_tv .trending_shows {
  padding-top: 20px !important;
}

.live_tv {
  padding-top: 70px !important;
}

.pt {
  margin-top: 50px !important;
}

/* ===== tv detail page ======== */

.movie-detail .inner .image3 {
  height: 308px;
  width: 549px;
  overflow: hidden;
}

.movie-detail .inner .image3 img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* ======== tv detail ========= */

.tv_detail {
  padding-bottom: 0 !important;
  margin-top: 0px !important;
}

.tv_detail .item {
  height: 1050px !important;
}

.tv_detail .inner {
  position: relative;
  margin-top: 200px;
  padding: 30px;
}

.tv_detail .inner .image2 {
  height: 452px;
  width: 300px;
  overflow: hidden;
  margin-top: -100px;
}

.tv_detail .inner .image2 img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tv_detail .text {
  max-width: 880px;
  position: relative;
}

.tv_detail .text h3 {
  font-family: "Montserrat Medium";
  color: #a8a8a8;
}

.tv_detail .text h1 {
  font-size: 60px;
  font-family: "Montserrat Bold";
}

.tv_detail .text h4 {
  font-size: 18px !important;
  font-family: "Montserrat Medium" !important;
  margin-bottom: 10px !important;
  letter-spacing: 0px !important;
  padding-left: 0 !important;
}

.tv_detail .text p {
  color: #aaaaaa;
}

.flex1 {
  display: flex;
}

/* ======= bar ========= */

.tab {
  margin-top: 100px;
  border-bottom: 1px solid #707070;
  position: relative;
}

.remodel {
  position: relative;
}

.tv_detail .inner .remodel h3 {
  margin: 20px 0 20px;
  color: #fff;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}

.tab button {
  outline: none;
  background: none;
  border: none;
  transition: all ease 0.5s;
  margin-right: 60px;
  font-size: 18px;
  color: #fff;
}

.blue {
  color: white;
  border-bottom: 1px solid #e51937 !important;
}

/* seasons */

.seasons h3 {
  color: #b2b2b2;
  font-family: "Montserrat Regular";
}

.seasons .text {
  margin-top: 20px;
}

.seasons h5 {
  color: #707070;
}

.seasons .image1 {
  height: 250px !important;
  overflow: hidden;
}

.seasons .image img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.mt {
  margin-top: 30px;
}

.tv_detail .image3 {
  position: relative;
  height: 308px;
  width: 549px;
}

.tv_detail .image3 img {
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.tv_detail .image3 .content1 {
  position: absolute;
  bottom: 25px;
  left: 0px;
  width: 100%;
  padding: 0 15px;
}

.bar1 {
  width: 100%;
}

.bar1 .progress {
  background: #333333 none repeat scroll 0 0 !important;
}

.bar1 .progress .progress-bar {
  background: #ffffff none repeat scroll 0 0 !important;
}

.tv_detail .image3 .content1 h3 {
  color: #e51937;
  font-size: 25px;
  margin-bottom: 15px;
}

.tv_detail .image3 .content1 h5 {
  font-size: 21px;
  color: #707070;
}

.overlay8 {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Gredient.png);
  background-size: cover;
  background-position: center;
}

/* ========= live tv guide ======= */

#full_width_banner_tv {
  padding: 0;
}

#full_width_banner_tv .item {
  height: unset;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
}

#full_width_banner_tv .item .image {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

#full_width_banner_tv .item .image img {
  width: 100%;
  z-index: -1;
  height: 100%;
}

#full_width_banner_tv .content {
  padding: 70px 0;
}

#full_width_banner_tv .content h3 {
  border-bottom: 1px solid #707070;
  padding-bottom: 10px;
  margin-bottom: 30px;
}

#full_width_banner_tv .outer .inner {
  display: flex;
  padding: 10px 0;
}

#full_width_banner_tv .outer .inner .col1 {
  background-image: url(./assets/images/livetv/Rectangle-59.png);
  background-position: center;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

#full_width_banner_tv .outer .inner .col1 .image1 {
  height: 74px;
  width: 111px;
  overflow: hidden;
}

#full_width_banner_tv .outer .inner .col1 .image1 img {
  object-fit: cover;
  object-position: center;
  height: 100%;
}

#full_width_banner_tv .outer .inner .col2 {
  background-image: url(./assets/images/livetv/Rectangle-3506.png);
  background-position: center;
  background-size: cover;
  background-position: center;
  position: relative;
  margin-right: 5px;
  border-radius: 0px 3px 3px 0px;
}

.overlay7 {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(./assets/images/livetv/Rectangle-3518.png);
  background-size: cover;
  background-position: center;
}

#full_width_banner_tv .outer .inner .col3 {
  background-image: url(./assets/images/livetv/Rectangle-3505.png);
  background-position: center;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 10px;
  border-radius: 3px;
}

#full_width_banner_tv .outer .inner .col3 .border {
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  border-bottom: 3px solid #db4f5a;
  width: 70%;
}

#full_width_banner_tv .outer .inner .text1 .flex {
  justify-content: space-between;
}

#full_width_banner_tv .outer .inner .text1 p {
  color: #9f9f9f;
  margin-bottom: 5px;
}

#full_width_banner_tv .outer .inner .text1 h4 {
  font-size: 18px;
  font-family: "Montserrat Bold";
  margin-bottom: 5px;
}

#full_width_banner_tv .outer .inner .col4 {
  background-image: url(./assets/images/livetv/Rectangle-3507.png);
  background-position: center;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 10px;
  border-radius: 3px;
}

#full_width_banner_tv .outer .inner .col5 {
  background-image: url(./assets/images/livetv/Rectangle-3507.png);
  background-position: center;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 10px;
  border-radius: 3px;
}

.m_l_r {
  margin: 0 5px;
}

.ten {
  width: 10%;
}

.five {
  width: 5%;
}

.fourty {
  width: 40%;
}

.twenty_five {
  width: 25%;
}

.thirty_five {
  width: 35%;
}

.fifteen {
  width: 15%;
}

.twenty {
  width: 20%;
}

.tv_banner_tv .item {
  height: 970px !important;
}

.popular-shows-carousel a {
  display: block;
}

/* ======== video player ========= */

section#videoplayer {
  position: relative;
  padding: 0px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(25, 33, 37);
  padding: 0px;
  overflow: auto;
  z-index: 99999;
  /* display: flex; */
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.popup ._inner {
  position: relative;
  /*background: #f7f8f9;
  */
  background: #fff;
  /*padding: 20px;*/
  margin: auto;
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
}

.popup.big-popup ._inner {
  max-width: 100%;
  height: 100vh;
}

.popup.small-popup ._inner {
  max-width: 420px;
}

.popup ._inner h3 {
  padding-right: 25px;
  margin-bottom: 20px;
}

.popup ._inner h4 {
  padding-right: 25px;
  margin-bottom: 15px;
}

.popBtn {
  cursor: pointer;
}

.crosBtn {
  position: absolute;
  top: 16px;
  left: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #8b9593;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  z-index: 9999999;
  opacity: 0;
  transition: all 150ms ease;
  width: unset;
  height: unset;
  max-width: 200px;
  align-items: center;
}

.popup ._inner:hover .crosBtn {
  opacity: 1;
}

.crosBtn:before {
  content: "\f104";
  line-height: 1;
  font-size: 16px;
  color: #fff;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.crosBtn .title h3 {
  margin-bottom: 0;
  color: #fff;
  margin-left: 12px;
  margin-top: 0;
  font-size: 16px;
}

.popup .list>li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 10px;
}

.popup .list>li>div:nth-child(1) {
  width: 120px;
  min-width: 120px;
  font-family: "Poppins SemiBold";
  margin-right: 10px;
}

.popup .list>li>div:nth-child(2) {
  width: 100%;
  color: #8b9593;
}

.popup .blockLst {
  margin: -15px;
}

#vidBlk video {
  width: 100%;
}

#contact form.contactForm label {
  min-height: unset;
  height: unset;
  margin-top: 7px;
  margin-left: 10px;
  padding-left: 0;
}

p.error {
  color: red !important;
  text-align: left;
}

p.error.flex {
  display: flex;
  flex-wrap: wrap;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c {
  background: #fff;
  border: none;
  border-radius: 10px;
  display: block;
  margin-bottom: 10px;
  padding: 0;
  position: relative;
  transition: all 0.5s ease;
  border-radius: 10px;
  box-shadow: 0 0 3px rgb(0 0 0 / 20%);
}

.faq-body {
  padding: 0 !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY {
  padding: 20px 0 !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_row-title-text__1MuhU {
  color: #4c4c4c !important;
  cursor: pointer;
  font-family: Poppins SemiBold;
  font-size: 16px !important;
  margin: 0;
  padding: 0 40px 0 25px;
  position: relative;
  transition: all 0.5s ease;
  z-index: 2;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-title__1YiiY .styles_icon-wrapper__2cftw {
  top: 17px !important;
}

.styles_faq-row-wrapper__3vA1D .styles_row-body__1NvUo .styles_faq-row__2YF3c .styles_row-content__QOGZd .styles_row-content-text__2sgAB {
  color: #4c4c4c !important;
  font-size: 14px !important;
  padding: 10px 25px 20px !important;
}

.giftPopup .crosBtn {
  color: #000;
  max-width: 70px;
}

.giftPopup .crosBtn:before {
  color: #000;
}

.giftPopup .giftBlk {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
}

.giftPopup.popup ._inner h4 {
  margin-bottom: 8px;
}

.giftPopup .giftBlk p {
  color: #2e2e2e;
}

.giftPopup .giftBlk .colL {
  width: 50%;
  padding: 10px;
}

.giftPopup .giftBlk .colR {
  width: 50%;
  padding: 10px;
}

.giftPopup .giftBlk .colL .image {
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  padding: 10px;
}

.giftPopup .giftBlk .colR .gift_content {
  padding: 0 0 0 20px;
}

.giftPopup .giftBlk .colR .gift_content .row {
  margin-top: 10px;
}

.giftPopup .giftBlk .colR .gift_content .row .donateBtn {
  gap: 10px;
}

.giftPopup .giftBlk .colR .gift_content .row .donate_btn {
  min-width: 60px;
  padding: 0 20px;
}

/* ============= dashboard ============= */

/* dashboard header */

.dashboard_header {
  background: #37454e;
  box-shadow: 0 5px 15px rgb(0 0 0 / 7%);
  min-height: 90px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding-top: 6px;
  z-index: 9999;
}

.dash_logo {
  width: 120px;
  filter: brightness(0) invert(1);
  padding-top: 6px;
}

.header_flx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashboard_header .col_nav {
  display: flex;
  align-items: center;
}

.dashboard_header .col_nav #nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col_nav #nav>li>.sub {
  width: 200px;
  left: -16px;
}

/* #nav>li:hover>.sub {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
  transition: all 0.2s ease-out;
} */

#nav>li>.sub>li,
#nav>li>.sub>li>a {
  display: block;
  text-align: left;
  padding: 10px;
  border-bottom: unset;
  color: #91989d;
}

#nav .sub li {
  padding: 10 !important;
}

.col_nav #nav>li>.sub>li>a {
  display: flex;
  align-items: center;
}

#nav>li>.sub>li,
#nav>li>.sub>li>a {
  display: block;
  text-align: left;
  padding: 0px;
}

#nav>li>.sub>li>a {
  padding: 5px 0;
}

.dashboard_header .col_nav #nav li {
  position: relative;
  font-size: 18px;
}

#nav>li.drop>a {
  position: relative;
  color: #181818;
}

.col_nav .icon img {
  width: 42px;
  height: 42px;
}

.col_nav .icon span {
  padding-left: 10px;
  font-size: 14px;
  color: #fff;
}

.ico,
.icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.col_nav .icon img {
  object-fit: cover;
  border-radius: 100%;
}

#nav>li>.sub {
  position: absolute;
  top: 100%;
  left: 15px;
  width: 150px !important;
  background: #233541;
  color: #91989d;
  text-align: left;
  margin-top: 10px;
  visibility: hidden;
  opacity: 0;
  border-radius: 6px;
  border: unset;
  transition: none;
  box-shadow: 0 15px 30px -5px rgb(0 0 0 / 7%);
  overflow: hidden;
  display: block;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  padding: 15px;
}

#nav>li.drop>a:after {
  content: "\f107";
  font-size: 12px;
  font-family: "FontAwesome";
  font-weight: bold;
  margin-left: 12px;
  line-height: 0;
  color: #fff;
}

#nav .sub li i {
  color: grey;
  padding-right: 6px;
  font-size: 14px;
  padding-top: 2px;
}

/* =========== profile ============== */

#profile {
  background-color: #121b22;
  margin-top: 90px;
  padding: 70px 0;
}

#profile .colL {
  width: 30%;
  padding-right: 10px;
}

#profile .colR {
  width: 70%;
  padding-left: 10px;
}

#profile .colR .inner {
  border-radius: 6px;
  padding: 30px 30px;
  background-color: #18252e;
  color: #fff;
}

#profile .colR .inner .inner-content {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding-bottom: 30px;
  align-items: center;
}

#profile .colR .inner .inner-content h4 {
  margin: 0%;
}

#profile .colR .inner .inner-content .line {
  position: absolute;
  border-bottom: 1px solid #333c44;
  top: 16px;
  left: 17%;
  width: 73%;
}

#profile .colR .inner .inner-content button.edit {
  background: none;
  border: none;
  outline: none;
  padding: 5px 10px;
  border-radius: 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
  align-self: center;
  display: flex;
  gap: 5px;
}

#profile .colR .inner .inner-content button.edit i {
  font-size: 12px;
}

#profile .txtGrp:not(.lblBtn)>label {
  position: relative;
  top: 0;
  display: flex;
  align-items: center;
  min-height: 0px;
  color: #91989d;
  line-height: 1;
  margin: 0 0 8px 0;
  margin-left: 0px;
  pointer-events: none;
  transition: all ease 0.5s;
  z-index: 1;
  font-family: "Montserrat Regular";
}

#profile .formRow {
  width: 90%;
}

#profile .txtGrp label~.txtBox:not(button) {
  padding: 0 12px 0;
}

#profile .txtGrp:not(:nth-last-child(1)) {
  margin-bottom: 8px;
}

#profile .update {
  position: relative;
}

#profile .update p {
  color: #519186;
}

#profile .update .line {
  position: absolute;
  border-bottom: 1px solid #519186;
  width: 40%;
  bottom: 0%;
}

#profile .update .line1 {
  position: absolute;
  border-bottom: 1px solid #519186;
  width: 30%;
  bottom: 0%;
}

#profile .tabs-lst {
  background: #18252e;
  border-radius: 6px;
  padding: 0 0px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  height: 100%;
}

#profile .tabs-lst li a {
  padding: 15px;
}

#profile .active {
  background-color: #233541;
}

#profile .active a {
  color: #fff;
}

#profile .tabs-lst li.active a:before {
  color: #fff;
}

#profile .tabs-lst li a:before {
  opacity: 1;
  color: #91989d;
}

#profile .tabs-lst li a:hover {
  background-color: #233541;
  color: #fff;
}

#profile .tabs-lst li a:hover::before {
  color: #fff;
}

#profile .txtBox {
  background-color: unset;
  border: 1px solid #333c44;
}

#profile .txtBox::placeholder {
  color: #fff;
  font-family: "Montserrat Medium";
}

#profile .eye {
  position: absolute;
  top: 54%;
  right: 4%;
  cursor: pointer;
}

#profile .eye i {
  font-size: 12px;
  color: #91989d;
}

/* ============ subscription page ============ */

#profile .colR .inner .inner-content .lined {
  position: absolute;
  border-bottom: 1px solid #333c44;
  top: 12px;
  left: 23%;
  width: 73%;
}

.SdonateBtn {
  gap: 76px;
  padding-top: 10px;
}

.block p {
  color: #656565;
  margin: 4px 0;
}

.redu {
  border-bottom: 1px solid #519186;
  color: #519186 !important;
  width: 55%;
  cursor: pointer;
}

.sub-list {
  padding-top: 40px;
}

.sub-list ul li {
  display: block;
}

.sub-list ul li a {
  color: #656565;
  margin-bottom: 8px;
}

.sub-list i.fa-solid.fa-check {
  color: #3c9505;
  background-color: #e8f5df;
  padding: 4px;
  font-size: 10px;
  border-radius: 50%;
  margin-right: 12px;
}

#profile .donate_btn {
  min-width: 130px;
  height: 40px;
  background: #233541;
  color: #fff;
  font-family: "Montserrat Medium";
  text-align: center;
  border: unset;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#profile .donate_btn_active {
  background: #dbfcf3 !important;
  color: #3cb995 !important;
  border: unset !important;
  min-width: 130px;
  height: 40px;
  border-radius: 5px;
  font-family: "Montserrat Medium";
  position: relative;
}

#profile .donate_btn_active.active {
  color: #dbfcf3 !important;
  background: #3cb995 !important;
}

#profile .member {
  width: 85%;
}

/* ============== payment info =============== */

.pay-inner .lines {
  position: absolute;
  border-bottom: 1px solid #333c44;
  top: 12px;
  left: 16%;
  width: 83%;
}

.box {
  border: 1px solid #333c44;
  border-radius: 10px;
  padding: 20px 12px;
  margin-bottom: 20px;
}

.bcontent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.box .bcontent h5 {
  margin: 0 0 2px;
}

.box-block {
  display: flex;
  align-items: center;
}

#profile p {
  color: #fff;
}

.box-block .icons i.fa-solid.fa-check {
  color: #3c9505;
  padding: 6px;
  font-size: 16px;
  border-radius: 50%;
  margin-right: 12px;
  border: 2px solid #3c9505;
}

.account_text .image {
  width: 20%;
  margin-bottom: 10px;
}

.account_text p {
  margin-bottom: 4px;
}

.account_text ul li {
  color: #848484;
  font-size: 12px;
  padding-right: 20px;
}

.account_text ul li span {
  color: #fff;
}

.box-block2 ul li {
  color: #848484;
}

.box .donate_btn {
  background-color: unset !important;
  border: 1px solid #848484 !important;
  color: #848484 !important;
}

#profile .bTns .donate_btn {
  border: 1px solid #519186;
  color: #519186;
  background-color: unset;
  width: 100%;
}

.pay-inner {
  position: relative;
}

.payment_history {
  margin-top: 30px;
}

.payment_history ul li h6 {
  color: #848484;
}

.payment_history ul li {
  width: 25%;
}

.payment_history ul li:nth-child(2) {
  width: 30%;
}

.payment_history ul li:nth-child(4) {
  width: 20%;
}

.payment_history ul {
  border-bottom: 1px solid #333c44;
  padding-bottom: 10px;
}

.payment_history ul li img {
  width: 35%;
}

/* =========== popups =============== */

.cmnpopup .inner {
  background-color: #18252e;
  max-width: 800px;
  margin: auto;
  color: #fff;
  padding: 48px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  position: relative;
}

.cmnpopup .inner label {
  color: #848484;
}

.cmnpopup .inner .txtBox {
  background-color: unset;
  border: 1px solid #333c44;
}

.cmnpopup .inner .txtBox::placeholder {
  color: #fff;
  opacity: 0.4;
}

.txtBox:focus::placeholder {
  opacity: 1;
}

.cmnpopup h2 {
  text-align: center;
  color: #fff;
}

.cmnpopup p {
  text-align: center;
  color: #8d9297;
}

.cmnpopup .bTns {
  margin-top: 30px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
}

.green {
  color: #fff;
  background-color: #11c693;
  min-width: 48%;
}

.grey {
  color: #848484;
  background-color: unset;
  border: 1px solid #848484;
  min-width: 48%;
}

.cmnpopup .row {
  margin-right: -10px;
  margin-left: -10px;
}

.cmnpopup .crosBtn {
  position: absolute;
  top: 16px;
  left: 16px;
  color: #8b9593;
  font-size: 16px;
  cursor: pointer;
  z-index: 9999999;
  transition: all 150ms ease;
  opacity: 1;
  display: inline-table;
}

.cmnpopup .crosBtn:before {
  content: "\f104";
  line-height: 1;
  font-size: 18px;
  color: #fff;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
}

.check_icon {
  height: 100px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.boxes {
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 400px;
  align-items: center;
  margin: 30px auto 0;
}

.boxes .col {
  width: calc(100% / 4);
}

.boxes .col .innerb {
  background-color: #233541;
  border: 1px solid #848484;
  color: #fff;
  padding: 20px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  font-size: 24px;
}

/* ============= email box =================== */

#email_box {
  /* background-color: #eee; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

#email_box .inner {
  /* background-color: #fff; */
  background-color: #18252e;
  max-width: 650px;
  margin: auto;
  color: #fff;
  padding: 48px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  position: relative;
  box-shadow: 0 0 15px rgb(0 0 0 / 40%);
}

#email_box .logo {
  background: #eee;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  margin: -72px auto 0;
  border-radius: 100%;
  width: 80px;
  height: 80px;
  float: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

#email_box .content {
  margin-top: 40px;
  width: auto;
}

#email_box .webBtn {
  margin-bottom: 20px;
  color: #fff !important;
  /* background-color: #3aa771; */
}

#email_box .content .strong {
  font-family: "Poppins SemiBold";
}

#email_box .content strong {
  font-family: "Poppins SemiBold";
}

#email_box .content .link {
  color: #3aa771;
  text-decoration: underline;
}

.StripeElement {
  border: 1px solid #333c44;
  border-radius: 4px;
  padding: 14px 10px;
}

.StripeElement>* {
  color: #fff
}

header.dashboard_header.tv_header {
  background: transparent;
  position: fixed;
  box-shadow: none;
  background-image: linear-gradient(180deg,
      rgba(0, 0, 0, 0.7) 10%,
      transparent);
}

header.fix {
  background-color: rgb(20, 20, 20) !important;
  background-image: unset !important;

}

.subscribe_upgrade {
  /* height: 700px; */
  background-image: url(./assets/images/subscibe.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribe_upgrade:before {
  content: "";
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

.subscribe_upgrade .innerBox {
  background-color: rgba(0, 0, 0, .75);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  max-width: 650px;
  margin: 0 auto;
  padding: 60px 68px 40px;
  color: #fff;
  text-align: center;
}

.subscribe_upgrade .innerBox p {
  color: #fff
}

/* ================tv_hover_grid=========== */
.hover_item_outer {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  overflow: hidden;
}

.tv_hover_grid {
  background: #000000cf;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.hover_item_outer:hover .tv_hover_grid {
  opacity: 1;
  visibility: visible;
  pointer-events: visible;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.tv_hover_grid ul {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tv_hover_grid ul li button {
  background-color: transparent;
  border: none;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background: #ddd;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.tv_hover_grid ul li button:hover {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  background: #db4f5a;
}

.tv_hover_grid ul li button:hover img {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  filter: brightness(0) invert(1);
}

.tv_hover_grid ul li button.active {
  background: #db4f5a;
}

.tv_hover_grid ul li button.active img {
  filter: brightness(0) invert(1);
}

.tv_hover_grid h4 {
  letter-spacing: 0px !important;
  padding-left: 0px !important;
}

.tv_hover_grid h5 {
  margin-bottom: 0 !important;
}

#trending_movies_shows {
  padding-top: 0;
}

#trending_movies_shows h3 {
  color: #b2b2b2;
  font-family: "Montserrat Regular";
}

#trending_movies_shows .item1 {
  display: block;
}

#trending_movies_shows .image1 {
  position: relative;
  /* height: 200px; */
  overflow: hidden;
  padding-bottom: 55%;
}

#trending_movies_shows .image1 img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


.poster_movies_shows {
  padding-top: 0;
  counter-reset: section;
}

.poster_movies_shows h3 {
  color: #b2b2b2;
  font-family: "Montserrat Regular";
}

.poster_movies_shows .item1 {
  display: block;
}

.poster_movies_shows .image1 {
  position: relative;
  overflow: hidden;
  padding-bottom: 80%;
}

.poster_movies_shows .image1 img {
  position: absolute;
  top: 0;
  /*left: 0;
  */
  bottom: 0;
  right: 0;
  width: 60% !important;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.poster_movies_shows .image1:before {
  position: absolute;
  counter-increment: section;
  content: counter(section);
  font-size: 21rem;
  color: #181818;
  font-family: 'Montserrat Bold';
  left: 1rem;
  line-height: 1;
  -webkit-text-stroke: 1.5px #626262;
}

.banner_btn_w_blk {
  display: flex;
  column-gap: 1rem;
}

#banner_tv .banner_btn_w_blk .white {
  background-color: #fff;
  color: #000 !important;
}

.popup.big-popup.video_popup ._inner {
  padding: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.popup.big-popup.video_popup ._inner #video,
.popup.big-popup.video_popup ._inner #video>div,
.popup.big-popup.video_popup ._inner #video iframe {
  width: 100%;
  height: 100%;
}

/* ========new about======== */
.new_about {
  padding-top: 0 !important;
}

.new_about .col {
  align-self: center;
  width: 50%;
}

.new_about .big_flex .col:nth-child(1) {
  /* padding-right: 4rem; */
  padding: 7rem 1.5rem 2rem;
}

.mini_flex_sec .col .image {
  position: relative;
  padding-bottom: 60%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
}

.mini_flex_sec .col .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new_about p {
  color: #6e6e6e;
  font-size: 1.7rem;
}

.new_about .col .big_image {
  width: 103%;
  height: 100%;
  margin-top: -14rem;
}

.new_about .col .big_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mini_flex_sec {
  margin-top: 2rem;
}

.new_about .mini_flex_sec .col:nth-child(1) {
  padding-right: 12rem;
}

/* .new_about .mini_flex_sec .col:nth-child(2){
  padding-left: 4rem;
} */
/* =============about_me_sec========== */
.about_me_sec {
  background-color: #f7f7f7;
}

.about_me_sec p {
  font-size: 1.7rem;
  color: #6e6e6e;
}

.about_me_sec .colL {
  width: 50%;
  padding-right: 1rem;
  align-self: center;
}

.about_me_sec .colR {
  width: 50%;
  padding-left: 1rem;
  align-self: center;
}

.about_me_sec .mini_flex_sec .colL {
  padding-right: 12rem;
}

.about_me_sec .mini_flex_sec .colL .image {
  position: relative;
  padding-bottom: 110%;
  overflow: hidden;
}

.about_me_sec .mini_flex_sec .colL .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about_me_sec h2 {
  margin-bottom: 2rem;
  font-family: 'Montserrat Bold';
}

.radius_img_flex {
  margin-top: 3rem;
}

.radius_img_flex .image {
  position: relative;
  padding-bottom: 90%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
}

.radius_img_flex .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* ===================new home page=========== */
.new_home_header {
  background-color: #00000026;
}

.dark_header {
  background-color: #121b22;
}

.new_home_banner {
  background-image: url(./assets/images/subscibe.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  margin-top: -11rem;
  display: flex;
  justify-content: center;
}

.new_home_banner .contain {
  width: 100%;
}

.new_home_banner:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, black, #32323285);
}

.new_home_banner .cntnt {
  max-width: 59rem;
  margin-top: 6rem;
}

.new_home_banner .cntnt h1 {
  color: #fff;
  font-family: "Montserrat Bold";
}

.new_home_banner .cntnt h1 em {
  display: block;
}

.new_home_banner .cntnt h3 {
  color: #fff;
  font-family: "Montserrat Light";
  opacity: 0.8;
}

.new_home_banner .cntnt p {
  color: #fff;
  margin-bottom: 2rem;
  opacity: 0.8;
}


.new_slider_sec {
  padding-top: 0 !important;
  background: #010101;
}

.new_slider_sec .cntnt {
  max-width: 60rem;
  margin: -11rem auto 1rem;
  color: #fff;
}

.new_slider_sec .cntnt h2 {
  font-family: "Montserrat SemiBold";
  color: #fff;
}

.new_slider_sec .cntnt p {
  color: #fff;
  opacity: 0.8;
}

/* =========new_slider======= */
.new_slider {
  min-height: 35rem;
  max-height: 45rem;
  /* height: 35rem; */
}

.new_slider .item .image {
  display: block;
  position: relative;
  padding-bottom: 50%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  border: 1px solid #333;
}

.new_slider .item .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.new_slider .owl-stage .owl-item.active.center {
  margin-top: 13rem;
  z-index: 1;
  transform: scale(1.3);
  padding-bottom: 3.5rem;
}

.new_slider .owl-stage .owl-item.active.center .image {
  padding-bottom: 55%;
}

.new_slider .owl-stage .owl-item.active.center+.active {
  margin-top: 6rem;
}

.new_slider .owl-stage .owl-item.active:has(+ .center) {
  margin-top: 6rem;
}

/* =======block_join_dim========== */
.block_join_dim {
  margin-top: 4rem;
  position: relative;
  border: 15px solid #333;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-bottom: none;
  padding: 12rem 8rem;

}

.block_join_dim:before {
  position: absolute;
  content: "";
  top: 0;
  left: -15px;
  bottom: 0;
  right: -15px;
  background: linear-gradient(0deg, black, #32323200);
}

.block_join_dim .flex {
  position: relative;
}

.block_join_dim .flex .colL {
  width: 50%;
  padding-right: 2rem;
  align-self: center;
}

.block_join_dim .flex .colL h2 {
  font-family: "Montserrat SemiBold";
  color: #fff;
}

.block_join_dim .flex .colL p {
  color: #fff;
  opacity: 0.8;
  margin-bottom: 2rem;
}

.block_join_dim .flex .colR {
  width: 50%;
  padding-left: 2rem;
  display: flex;
  position: relative;
  /* padding-left: 10rem; */
  justify-content: flex-end;
}

.block_join_dim .flex .colR .image {
  width: 15rem;
  position: relative;
  padding-bottom: 45%;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  overflow: hidden;
  border: 1px solid #333;
}

.block_join_dim .flex .colR .image img {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.block_join_dim .flex .colR .image:nth-child(2) {
  transform: scale(1.3);
  z-index: 1;
}

.block_join_dim .flex .colR:before {
  position: absolute;
  content: '';
  top: -9rem;
  right: 3rem;
  bottom: 0;
  background-color: #ffffff21;
  filter: blur(27px);
  width: 39rem;
  height: 39rem;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

/* ===============sign_up_new========== */
.sign_up_new {
  margin-top: 5rem;
}

.sign_up_new .inner_sign_up_new {
  background-image: url(./assets/images/subscibe.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 2.5rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
}

.sign_up_new .inner_sign_up_new:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #000000cf;
}

.sign_up_new .inner_sign_up_new * {
  position: relative;
}

.sign_up_new .inner_sign_up_new .cntnt {
  max-width: 60rem;
  text-align: center;
  margin: 0 auto;
}

.sign_up_new .inner_sign_up_new .cntnt h2 em {
  color: #11c693;
}

.sign_up_new .inner_sign_up_new .cntnt .trafic_logo {
  max-width: 14rem;
  margin: 0 auto;
}

.sign_up_new .inner_sign_up_new .cntnt .trafic_logo img {
  filter: brightness(0) invert(1);
}

.sign_up_new .inner_sign_up_new .cntnt h2 {
  margin-top: 1rem;
}

.inner_sign_up_new .left_image,
.inner_sign_up_new .right_image {
  position: absolute;
  content: "";
  width: 14rem;
}

.inner_sign_up_new .left_image {
  left: 4rem;
  top: -3rem;
}

.inner_sign_up_new .right_image {
  right: 4rem;
  bottom: -9rem;
}

.logos_lst {
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  max-width: 70rem;
  margin: 1rem auto 4rem;
}

.logos_lst .logo_inner_icon {
  max-width: 8rem;
  margin: 0 auto;
}

.dim_text_text .webBtn {
  min-width: 210px;
}

.dim_text_text p {
  color: #fff;
  opacity: 0.6;
  margin-top: 1rem;
  font-size: 1.2rem;
}

.last_cntnt {
  max-width: 70rem;
  margin: 3rem auto 0;
}

.last_cntnt p {
  color: #fff;
  opacity: 0.8;
}

.last_cntnt p a {
  color: #11c693;
}

/* ================faq_sec============ */
.faq_sec {
  padding-top: 0 !important;
  padding-bottom: 8rem !important;
  background: #010101;
}

.faq_sec h2 {
  font-family: "Montserrat SemiBold";
  color: #fff;
  margin-top: 0;
}

.faq_blk_sep {
  max-width: 70rem;
  margin: 3rem auto 5rem;
}

.MuiPaper-root {
  background-color: #2d2d2d !important;
}

.MuiAccordionSummary-content p {
  color: #fff;
  font-size: 1.4rem;
  opacity: 0.8;
}

.MuiSvgIcon-root {
  width: 1.5em !important;
  height: 1.5em !important;
  color: #fff;
}

.MuiAccordionDetails-root p {
  color: #fff;
  font-size: 1.3rem;
  opacity: 0.8;
}

.MuiAccordion-rounded {
  margin-bottom: 1.5rem;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
}

.last_cntnt form {
  display: flex;
  gap: 1.5rem;
  max-width: 50rem;
  margin: 2rem auto 0;
}

.last_cntnt form input {
  background: #2d2d2d;
  border: none;
  border-radius: 2px;
  height: 4rem;
  flex: 1;
  padding: 1rem;
  color: #fff;
}

.last_cntnt form button {
  background: #11c693;
  border: none;
  height: 4rem;
  border-radius: 2px;
  flex: 0.5;
  color: #fff;
  font-size: 14px;
  font-family: "Montserrat SemiBold";
}

/* ============new_footer========== */
.new_footer {
  background-image: url(./assets/images/banner-image.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}

.new_footer:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #14161bf5;
}

.new_footer .contain {
  max-width: 1250px;
}

.new_footer .footerLogo {
  margin-bottom: 2rem;
}

.new_footer h5 {
  margin-bottom: 15px;
  padding-bottom: 0;
}

.new_footer .social {
  margin: 15px -3px -3px;
}

.new_footer h5:after {
  display: none;
}

.new_footer li,
.new_footer a,
.new_footer p {
  opacity: 0.7;
}

.new_footer .social li a,
.new_footer .social li {
  opacity: 1;
}

.new_footer .br_between {
  padding: 1rem 0;
}

.new_footer .copyright {
  background-color: transparent;
  border: none;
  margin-top: 20px;
}

.new_footer a:hover {
  color: #11c693;
}

.new_footer .social li a:hover {
  background-color: #11c693;
}

/* =======new login========== */
.new_logon {
  background-image: url(./assets/images/subscibe.jpeg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
  max-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  padding: 4rem 0 !important;
  /* margin-top: -8rem; */
}

.new_logon:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: linear-gradient(0deg, black, #32323285);
}

.new_logon .contain {
  width: 100%;
}

.new_logon .log_blk {
  width: 100%;
  max-width: 380px;
  margin: 0 auto;
  padding: unset;
}

.form-sign-up.new_logon .log_blk {
  max-width: 650px;
}

.new_logon .log_blk .image {
  max-width: 15rem;
  height: 10rem;
  margin: 0 auto 2rem;
  overflow: hidden;
}

.new_logon .log_blk .image a {
  display: block;
  max-width: 15rem;
  height: 10rem;
  margin: auto;
  overflow: hidden;
}

.new_logon .log_blk .image a img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.new_logon .log_blk .inner {
  background: #0000009c;
  padding: 25px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  box-shadow: 0 5px 20px rgba(18, 15, 56, 0.07);
}

.new_logon .log_blk .inner h2 {
  margin-top: 0;
  margin-bottom: 2rem;
  color: #fff;
}

.new_logon .log_blk .inner h5 {
  margin-top: 0;
  color: #fff;
}

.new_logon .log_blk .haveAccount {
  color: #fff;
}

.new_logon .log_blk .haveAccount span {
  margin-right: .3rem;
}

.new_logon .log_blk .webBtn {
  width: 100%;
}

.new_logon .log_blk .txtBox {
  background-color: transparent;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border: none;
  border-bottom: 1px solid #ddd;
  padding-left: 0;
  padding-right: 0;
  color: #fff;
}

.new_logon .log_blk .txtBox::placeholder {
  color: #fff;
  opacity: 0.7;
}

.new_logon .log_blk .txtBox:-webkit-autofill {
  -webkit-text-fill-color: #fff;
}

.form-sign-up.new_logon .log_blk .radioSizeLst {
  margin: -1rem;
}

.form-sign-up.new_logon .log_blk .radioLst .innerCol label {
  background: #0e0e0e;
  border: 1px solid #323232;
  color: #b7b7b7;
}

.form-sign-up.new_logon .privacy a {
  color: #db4f5a;
  margin: 0 0.2rem;
}

/* ===============responsive============== */

/*
|----------------------------------------------------------------------
|       Media Queries
|----------------------------------------------------------------------
*/

@media screen and (min-width: 992px) and (max-width: 1200px) {

  /*_____ Common _____*/
  .owl-carousel.owl-theme .owl-nav .owl-prev {
    left: -15px;
  }

  .owl-carousel.owl-theme .owl-nav .owl-next {
    right: -15px;
  }

  nav>ul>li {
    padding: 0 7px;
  }

  nav>ul>li>a {
    font-size: 13px;
  }

  .subbscribe .imagee:nth-child(1) {
    top: 30px;
    left: 20px;
  }

  .subbscribe .imagee:nth-child(2) {
    top: 35px;
  }

  #contact .colR {
    width: 55%;
    padding: 20px 0% 20px 0px;
  }

  #contact {
    height: auto;
  }

  #contact .colL {
    height: 1000px;
  }

  #contact .colL img {
    height: 100%;
    object-fit: cover;
    object-position: left;
  }

  .search-box .colR {
    padding-left: 20px;
  }

  .form-blk .colR {
    width: 50%;
    padding: 25px;
  }

  .form-blk .colL {
    width: 50%;
  }

  .form-blk .bTn,
  .form-blk .bTn a {
    gap: 5px;
  }

  #profile .formRow {
    width: 100% !important;
  }

  /*_____ Navigation _____*/
  .toggle {
    display: flex !important;
  }

  header {
    height: auto !important;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-flow: wrap;
    float: none;
    display: none !important;
    height: auto !important;
    background: rgb(255 255 255 / 90%);
    padding: 20px 50px 15px;
    margin: 0 !important;
    visibility: hidden;
    opacity: 0;
    backdrop-filter: saturate(180%) blur(20px);
    overflow: auto;
    z-index: 2;
  }

  nav.active {
    display: flex !important;
    visibility: visible;
    opacity: 1;
  }

  #nav {
    align-items: center;
    flex-flow: wrap;
  }

  #nav>li {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 100%;
    padding: 15px;
  }

  nav>ul>li>a {
    font-size: 20px;
  }

  /* #nav > li > .sub {
    position: static;
    display: none;
    width: 100%;
    max-height: none;
    background: transparent;
    visibility: visible;
    opacity: 1;
    text-align: center;
    margin: 0 auto !important;
    border: 0;
    border-radius: 0;
    transition: none;
    box-shadow: none;
  }
  #nav > li > .sub > li {
    display: flex;
    justify-content: center;
  }
  #nav > li > .sub > li > a {
    background: transparent;
    border-top: 0;
    border-bottom: 0;
  } */
  nav #cta {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  .topNav {
    display: none;
  }

  .index {
    padding-top: 60px;
  }

  .new_logon .log_blk {
    padding: unset;
    width: 100%;
  }

  #nav>li:hover>.sub {
    visibility: hidden;
    opacity: 0;
    margin-top: 0;
    transition: all 0.2s ease-out;
  }

  #nav>li>.sub.active {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transition: all 0.2s ease-out;
  }

  /* =====live stream====== */
  #banner_tv .flexDv {
    height: unset;
  }

}

@media screen and (max-width: 991px) {

  /*_____ Navigation _____*/
  .topNav {
    display: none;
  }

  .toggle {
    display: flex !important;
  }

  header {
    height: auto !important;
  }

  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    flex-flow: wrap;
    float: none;
    display: none !important;
    height: auto !important;
    background: rgb(255 255 255 / 90%);
    padding: 20px 50px 15px;
    margin: 0 !important;
    visibility: hidden;
    opacity: 0;
    backdrop-filter: saturate(180%) blur(20px);
    overflow: auto;
    z-index: 2;
  }

  nav.active {
    display: flex !important;
    visibility: visible;
    opacity: 1;
  }

  #nav {
    align-items: center;
    flex-flow: wrap;
  }

  #nav>li {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    width: 100%;
    padding: 15px;
  }

  nav>ul>li>a {
    font-size: 20px;
  }

  /* #nav > li > .sub {
    position: static;
    display: none;
    width: 100%;
    max-height: none;
    background: transparent;
    visibility: visible;
    opacity: 1;
    text-align: center;
    margin: 0 auto !important;
    border: 0;
    border-radius: 0;
    transition: none;
    box-shadow: none;
  }
  #nav > li > .sub > li {
    display: flex;
    justify-content: center;
  }
  #nav > li > .sub > li > a {
    background: transparent;
    border-top: 0;
    border-bottom: 0;
  } */
  nav #cta {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;
  }

  #nav>li:hover>.sub {
    visibility: hidden;
    opacity: 0;
    margin-top: 0;
    transition: all 0.2s ease-out;
  }

  #nav>li>.sub.active {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    transition: all 0.2s ease-out;
  }

  .topNav .colL {
    width: 70%;
  }

  .topNav .colR {
    width: 30%;
  }

  .topNav a {
    font-size: 11px;
  }

  footer .social>li {
    margin: 5px -1px;
  }

  .index {
    padding-top: 60px;
  }

  #banner_tv .flexDv {
    height: unset;
  }

  /*_____ Common _____*/
  .owl-carousel.owl-theme .owl-nav .owl-prev {
    left: -15px;
  }

  .owl-carousel.owl-theme .owl-nav .owl-next {
    right: -15px;
  }

  .sub-banner .cntnt,
  .cntntt,
  .caught .content,
  .warrior .cntnt,
  .lindsay .col,
  .cases .vidBlk,
  .cases .image,
  .search-box .colL,
  .donate_now-sec .donate_now-flex .colR,
  .content {
    width: 100%;
  }

  .fund_form .coll,
  .become_partner .coll,
  .share-sec .share-flex .colL,
  .cases .flexRow>.col,
  .listen_now .colL {
    align-self: center;
  }

  .form-blk .bTn,
  .impactt .flex,
  .ways .flexRow {
    justify-content: center;
  }

  .donate_now-sec .donate_now-flex .colL,
  .subbscribe .imagee:nth-child(1),
  .subbscribe .imagee:nth-child(2) {
    display: none;
  }

  .merchandise_products .flexRow>.col,
  .event-banner .colR,
  .event-banner .colL,
  .form-blk .colL,
  .form-blk .colR,
  .impactt .col,
  .feature-news .col,
  #join .col,
  .imagesGrid ._col,
  .ways .flexRow>.col,
  #banner .vid .col {
    width: calc(100% / 2);
  }

  /*_____ Home _____*/

  .lives .imagee:nth-child(2) {
    top: 98px;
  }

  .lives .imagee:nth-child(1) {
    left: 12px;
    width: 105px;
  }

  .danger .flexRow .inner {
    gap: 20px;
    padding: 50px 10px 50px 11px;
  }

  #story_detail .colL,
  .cropSec .colL,
  .aboutSec .colL {
    width: 100%;
    padding-right: 0;
  }

  #story_detail .colR,
  .aboutSec .colR,
  .search-box .colR {
    width: 100%;
    padding-left: 0;
    margin-top: 30px;
  }

  .progress-box {
    padding-left: 0;
  }

  /*_____ Other pages _____*/
  .cropSec {
    clip-path: polygon(0 0, 100% 0%, 100% 91%, 0 100%);
  }

  .lindsay .col .image {
    width: 60%;
    margin: 0 auto;
  }

  .warrior {
    background-position: revert;
  }

  .become_partner {
    padding-bottom: 60px !important;
  }

  .form-blk .colR {
    padding: 50px 20px;
  }

  .event-banner .colR form {
    min-width: 100%;
  }

  .event .itmBlk>.cta {
    justify-content: center !important;
    margin: 0 !important;
    max-width: none;
  }

  .event .itmBlk {
    flex-direction: column;
    text-align: center;
    padding: 30px;
  }

  .event .itmBlk .ico {
    margin: 0 auto;
  }

  .cmn-tabs .colR .inner .left img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  #contact .colR {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
  }

  #contact .colL {
    width: 100%;
    height: 300px;
    padding-right: 0px;
  }

  #contact .colL img {
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  #contact {
    height: auto;
  }

  #contact .colR .coll {
    width: calc(100% / 3);
  }

  .col-lg-6.col-md-6.col-sm-6bcol-xs-6.col-xx-6 {
    width: 50%;
  }

  #profile .formRow {
    width: 100% !important;
  }

  #profile .colL {
    display: none;
  }

  #profile .colR {
    width: 100% !important;
    padding-left: 0px !important;
  }

  #profile .formRow {
    margin: 0px !important;
  }

  /* =======new about======== */
  .new_about .col {
    width: 100%;
  }

  .new_about .col .big_image {
    margin-top: 0;
    width: 100%;
  }

  .big_flex {
    flex-direction: column-reverse;
  }

  .new_about .mini_flex_sec .col:nth-child(1) {
    padding-right: 1.5rem;
  }

  .big_flex .col:nth-child(2) {
    padding-top: 4rem;
  }

  .big_flex .col:nth-child(1) {
    padding-top: 1rem !important;
  }

  .about_me_sec .colL {
    width: 100%;
  }

  .about_me_sec .mini_flex_sec .colL {
    padding-right: 0;
  }

  .about_me_sec .colR {
    width: 100%;
    padding-left: 0;
  }

  .radius_img_flex {
    flex-direction: column-reverse;
  }

  .radius_img_flex .colR {
    margin-bottom: 2rem;
  }

  /* =====home page====== */
  .block_join_dim {
    padding: 3rem 3rem;
  }

  .block_join_dim .flex .colL {
    width: 100%;
    padding-right: 0;
  }

  .block_join_dim .flex .colR {
    width: 100%;
    padding-left: 0;
    justify-content: center;
    margin-top: 7rem;
  }

  .block_join_dim .flex .colR .image {
    width: 20rem;
  }

  .sign_up_new {
    margin-top: 10rem;
  }

  .inner_sign_up_new .left_image,
  .inner_sign_up_new .right_image {
    display: none;
  }

  .faq_sec,
  .new_slider_sec {
    margin-top: -.4rem;
  }

  /* ======new home====== */
  .new_home_banner {
    height: unset;
    padding-top: 14rem !important;
  }

  .new_slider_sec .cntnt {
    margin: 0rem auto 2rem;
  }

  .new_logon .log_blk {
    padding: unset;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {

  /*_____ All Site _____*/
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 32px;
  }

  h3 {
    font-size: 22px;
  }

  /*_____ Common _____*/
  .lives .imagee:nth-child(1),
  .lives .imagee:nth-child(2),
  .share-sec .share-flex .colL,
  .become_partner .coll:nth-child(1),
  .fund_form .coll:nth-child(1),
  .form-blk .colL,
  .topNav .colL {
    display: none;
  }

  .cmnpopup .inner {
    padding: 20px !important;

    max-width: 100% !important;
  }

  .cmnpopup form {
    margin-top: 30px !important;
  }

  .flex-box-help .col,
  .help-flex .col,
  .topNav .colR,
  .event-banner .colR,
  .event-banner .colL,
  .rescue-flex p,
  .impactt .col,
  .danger .flexRow>.col,
  .feature-news .col,
  #join .col,
  .fund_form .coll,
  .imagesGrid ._col,
  .ways .flexRow>.col,
  #banner .vid .col,
  .form-blk .colR,
  .involved .hire_div,
  .cases .flexRow>.col,
  .caught .flexRow>.col,
  .become_partner .coll,
  .fund_raiser .flexRow>.col,
  footer .flexRow>.col:nth-child(1),
  .merchandise_products .flexRow>.col,
  .half-slider .h-slider #owl-video .owl-item.active .inner .txt h4 {
    width: 100%;
  }

  footer .flexRow>.col {
    width: calc(100% / 2);
  }

  .news_more .col2,
  .news_more .col1,
  .current_aff ._colM,
  .current_aff ._col,
  .service_blk .colL,
  .national-directory .colL,
  .donate-cta ._colL,
  .cmn-tabs .colL,
  .cropSec_home .colL,
  .detail_merchandise .flex .colL,
  .listen_now .colL {
    width: 100%;
    padding-right: 0px;
  }

  .cropSec_home .colR,
  .share-sec .share-flex .colR,
  .news_more .col3,
  .service_blk .colR,
  .national-directory .colR,
  .donate-cta ._colR,
  .cmn-tabs .colR,
  .detail_merchandise .flex .colR,
  .listen_now .colR {
    width: 100%;
    padding-left: 0px;
    margin-top: 30px;
  }

  /*_____ Pages _____*/
  .event-banner,
  .national-directory {
    height: auto;
  }

  .national-directory-search-blk {
    padding-top: 60px !important;
  }

  .current_aff ._colM {
    padding: 0px;
  }

  #banner .flex_1 .video,
  .rescue-flex .text {
    flex: auto;
  }

  .rescue-flex .bTn {
    margin-top: 30px;
  }

  .rescue-flex {
    justify-content: center;
  }

  .fund_raiser .flexRow:nth-child(even),
  .fund_raiser .flexRow:nth-child(odd),
  .cases .flexRow:nth-child(even),
  .cases .flexRow:nth-child(odd) {
    flex-flow: column-reverse;
  }

  #banner .flex_1 .side-poster {
    margin: 0 auto;
  }

  .event-banner .colR {
    margin-top: 30px;
  }

  .subbscribe .lgBtn {
    padding: 0 10px;
  }

}

@media screen and (max-width: 580px) {

  /*_____ col-xx-? _____*/
  .col-xx-12 {
    width: 100%;
  }

  .col-xx-11 {
    width: 91.6667%;
  }

  .col-xx-10 {
    width: 83.3334%;
  }

  .col-xx-9 {
    width: 75%;
  }

  .col-xx-8 {
    width: 66.6667%;
  }

  .col-xx-7 {
    width: 58.3334%;
  }

  .col-xx-6 {
    width: 50%;
  }

  .col-xx-5 {
    width: 41.6667%;
  }

  .col-xx-4 {
    width: 33.3334%;
  }

  .col-xx-3 {
    width: 25%;
  }

  .col-xx-2 {
    width: 16.6667%;
  }

  .col-xx-1 {
    width: 8.3334%;
  }

  .SdonateBtn {
    gap: 20px !important;
  }

  #profile .colR .inner {
    padding: 30px 15px !important;
  }

  #profile .colR .inner .inner-content .line {
    display: none !important;
  }

  #profile .colR .inner .inner-content .lined {
    display: none !important;
  }

  .pay-inner .lines {
    display: none !important;
  }

  .bcontent {
    display: block !important;
  }

  .icons {
    display: none;
  }

  /* .payment_history ul li h6 {
    font-size: 10px;
  }

  .payment_history ul li h5 {
    font-size: 10px;
  } */

  .payment_history ul li {
    width: 50% !important;
  }

  /*_____ Home _____*/
  .progress-box,
  .event .itmBlk .ico,
  .event .itmBlk .txt ul li {
    width: 100%;
  }

  .event .itmBlk>.txt .view {
    flex-direction: column;
  }

  .event .itmBlk {
    text-align: left;
    padding: 15px;
  }

  .event .itmBlk .webBtn {
    padding: 0px 25px;
  }

  .circle_percent {
    margin: 0 auto;
  }

  .ProgressBox .inner_ {
    width: 92%;
  }

  .ProgressBox {
    flex-direction: column;
  }

  /*_____ Cart Page _____*/
  #cart {
    padding-top: 50px;
  }

  #cart .cart-heading {
    text-align: left;
  }

  #cart table,
  #cart table tbody,
  #cart table tbody tr,
  #cart table tbody tr>* {
    display: block;
    width: auto;
    text-align: left;
  }

  #cart table {
    width: 90%;
    margin: 0 auto;
  }

  #cart table thead {
    display: none;
  }

  #cart table tbody tr {
    margin-bottom: 30px;
    display: block;
    border: solid 2px #65388b63;
    box-shadow: 0 7px 14px 0 rgb(59 65 94 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  }

  #cart .simpleBtn {
    margin: 20px 0;
  }

  .text-left.bTn,
  .text-left .bTn {
    flex-direction: column;
  }

  #cart table tbody tr>* {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    padding: 15px;
    border: 0;
    border-bottom: 1px solid #323538;
  }

  #cart table tbody tr.configRow>* {
    padding: 0;
  }

  #cart table tbody tr>*:before {
    display: block;
  }

  #cart table tbody tr.configRow>*:before {
    display: none;
  }

  #cart .pro_cart {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    margin: 0;
  }

  .shopTable .qty {
    margin: 0;
    height: auto;
  }

  .shopTable .qtyBtn .qty {
    height: 24px;
    margin: 0;
  }

  #cart table tfoot {
    display: table;
    width: 100%;
    /*background: #f8f9fa;*/
    /*border-top: 1px solid #ddd;*/
  }

  #cart table tfoot tr>* {
    width: 50%;
    padding: 10px 15px;
  }

  #cart table tfoot tr>*:nth-last-child(1) {
    text-align: right;
  }

  .shopTable .configBlk {
    padding: 15px;
  }

  #cart .policyBlk .list>li {
    width: 100%;
  }

  #cart .shopTable table tfoot tr>*:nth-child(1) {
    text-align: left;
  }

  #cart .shopTable table tfoot tr>* {
    border-bottom: 1px solid #ddd;
  }

  /*_____  Checkout _____*/
  #checkout {
    padding-top: 40px;
  }

  #checkout .flexRow>.col1 {
    width: 100%;
  }

  #checkout .flexRow>.col2 {
    width: 100%;
  }

  #checkout hr {
    border-color: #fff;
  }

  /* ======new home====== */
  .new_slider {
    min-height: unset;
    max-height: unset;
  }

  .sign_up_new {
    margin-top: 5rem;
  }

  .copyright .inner {
    flex-direction: column-reverse;
    align-items: center;
    row-gap: 1rem;
  }
}

@media screen and (max-width: 480px) {

  /*_____ col-xx-? _____*/
  .col-xx-12,
  .col-xx-11,
  .col-xx-10,
  .col-xx-9,
  .col-xx-8,
  .col-xx-7,
  .col-xx-6,
  .col-xx-5,
  .col-xx-4,
  .col-xx-3,
  .col-xx-2,
  .col-xx-1 {
    width: 100% !important;
  }

  /*_____ common _____*/
  [index]>section {
    padding: 40px 0;
  }

  .donate-cta .ab_image,
  [donate_pay] .sub-banner,
  .involved .ab_image {
    display: none;
  }

  .cropSec .playerImg,
  .donate-cta ._inner .bTn a,
  .impact_counter .flexRow>.col {
    width: 100%;
  }

  .partner .col,
  .spponsers .flexRow>.col {
    width: calc(100% / 2);
  }

  .cmn-tabs .colR .inner .left {
    width: 100%;
    padding-right: 0px;
  }

  /* .ProgressBox .inner_ {
    width: 400px;
  } */

  .listen_now .colR,
  .cmn-tabs .colR .inner .right {
    width: 100%;
    padding-left: 0px;
    margin-top: 30px;
  }

  /*_____ Other pages _____*/
  footer .social>li>a {
    min-width: 35px;
    width: 35px;
  }

  .event-banner .contnt h1,
  .sub-banner .cntnt h1 {
    font-size: 30px;
  }

  .copyright p {
    flex: auto;
  }

  footer .smLst {
    justify-content: flex-end;
  }

  .cropSec .playerBlk {
    margin-right: 0;
  }

  .popup ._inner {
    padding: 5px;
  }

  .sub_pop .crosBtn {
    color: #ffffff;
  }

  .donate_now-sec .formRow {
    margin-top: 10px;
  }

  .donate_btn {
    min-width: 70px;
  }

  .donateBtn {
    gap: 8px;
  }

  .detail_merchandise .lgBtn,
  .donateBtn .lgBtn {
    padding: 0 40px;
  }

  [donate_pay] .donate_now-sec .formRow {
    margin-top: 70px;
  }

  .googleBtn img,
  .fbBtn img {
    width: 9%;
  }

  .form-sign-up .radioSizeLst .innerCol {
    padding: 5px;
  }

  .form-blk .bTn a {
    gap: 5px;
  }

  .googleBtn,
  .fbBtn {
    padding: 0 10px;
    font-size: 12px;
  }

  .form-blk .bTn {
    justify-content: space-between;
    gap: 2px;
  }

  [cart] .sub-banner {
    height: 200px;
  }

  [checkout] .sub-banner {
    height: 200px;
  }
}

@media screen and (max-width: 380px) {

  /*_____ All Site _____*/
  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 20px;
  }

  /*_____ Home _____*/
  .ProgressBox .inner_ {
    width: 86%;
  }

  /*_____ Common _____*/
}

@media screen and (max-width: 320px) {
  /*_____ Home _____*/
}

/*
|----------------------------------------------------------------------
|       Media Queries Height
|----------------------------------------------------------------------
*/

@media screen and (max-height: 420px) {}

@media screen and (min-height: 768px) {}

@media screen and (min-height: 1040px) {}

.textual_page,
.terms_bg {
  background: #010101
}

.textual_page .text_blk {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  box-shadow: 0 0 20px #605f5f91;
  margin: 0 auto;
  max-width: 1000px;
  padding: 30px;
}

.textual_page .text_blk .sec_heading {
  margin-bottom: 0;
}

.sec_heading h2 {
  color: #333;
  /* font-family: Poppins SemiBold; */
  font-size: 35px;
}

.textual_page .text_blk * {
  color: #fff
}